/**********************************************************************
 * simple context menu handling app
 *********************************************************************/

const DEBUG = false;

class ContextMenuApp {
    /**
     *
     * @param opts
     */
    constructor(opts) {
        this.bindings();
        // $(document).on('content:changed', () => {
        //     this.bindings();
        // });

        // TODO: improve.. detect click *outside* ctx menu to handle close
        $(document).on('click', (e) => {
            if (!$(e.target).closest('[data-ctx-menu]').length) {
                $('[data-ctx-menu]').removeClass('context-menu-expanded');
            }
        });

    };

    /**
     *
     */
    bindings() {

        if (DEBUG) console.log('ContextMenuApp: - bindings');

        $(document).on('click', '[data-ctx-menu] [data-ctx-menu-toggle]', (e) => {
            e.preventDefault();
            if (DEBUG) console.log('ContextMenuApp: - click received');
            this.toggle_menu($(e.currentTarget));
        });

        // $('[data-ctx-menu]').not('[data-ctx-menu-registered="1"]').each((i, el) => {
        //
        //     let item = $(el);
        //
        //     $('[data-ctx-menu-toggle]', item).on('click', (e) => {
        //         e.preventDefault();
        //         this.toggle_menu($(e.currentTarget));
        //     });
        //     item.attr('data-ctx-menu-registered', '1');
        //
        // });
    };

    /**
     *
     * @param el
     */
    toggle_menu(el) {

        let ct = el.parents('[data-ctx-menu]');

        if (ct.hasClass('context-menu-expanded')) {
            this.hide(ct);
        } else {
            this.show(ct);

            let panel = ct.find('.context-menu-panel');
            ct.removeClass('align-top');
            if(! isInViewport(panel[0])) {
                ct.addClass('align-top');
            }
        }
    };

    /**
     *
     * @param container
     */
    show(container) {

        // hide other ctx menus
        $('[data-ctx-menu]').not(container).removeClass('context-menu-expanded');

        container.addClass('context-menu-expanded');
        $(container).on('click', '.action', (e) => {
            this.hide();
        })


    };

    /**
     *
     * @param container
     */
    hide(container = null) {
        if (container) {
            container.removeClass('context-menu-expanded');
        } else {
            $('[data-ctx-menu]').removeClass('context-menu-expanded')
        }
    };

}

module.exports = ContextMenuApp;


// https://gist.github.com/jjmu15/8646226
function isInViewport(element) {
  let rect = element.getBoundingClientRect();
  let html = document.documentElement;
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || html.clientHeight) &&
    rect.right <= (window.innerWidth || html.clientWidth)
  );
}
