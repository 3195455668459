import Vue from 'vue';
import store from "../../store";
import APIClient from '../../api/client';
import Visual from '../../components/visual.vue';
import PlayerDetail from './components/PlayerDetail.vue'
import Release from './components/release.vue'
import Playhead from './components/playhead.vue'
import PlayIcon from './components/play-icon.vue'
import SkipIcon from './components/skip-icon.vue'
import {template_filters} from '../../utils/template-filters';


// import Playing from '../../components/playing.vue';

const DEBUG = false;

const pre_process_loaded_items = (results) => {
    results.forEach((item_to_play, i) => {
        item_to_play.items.forEach((item, j) => {
            // item = pre_process_item(item);
            item.key = `${item.media.uuid}-${i}-${item_to_play.uuid}-${j}`;
        });
    });
    return results;
};

const PlayerApp = Vue.extend({
    components: {
        Visual,
        Release,
        Playhead,
        'player-detail': PlayerDetail,
        'play-icon': PlayIcon,
        'skip-icon': SkipIcon,
    },
    data() {
        return {
            loading: false,
            playing: false,
            expanded: false,
            //items_to_play: [],
            //current_item: null,
            current_media: null,
            //playlist_is_visible: false
        }
    },
    computed: {
        items_to_play: () => store.state.player.items_to_play,
        current_item_key: () => store.state.player.current_item_key,

        items_to_play_flat: function () {
            return this.items_to_play.reduce((a, i) => a.concat(i.items), [])
        },

        current_item: function () {
            const _c = this.items_to_play_flat.filter((i) => i.key === this.current_item_key);
            return _c.length > 0 ? _c[0] : null;
        },

        has_items_to_play: function () {
            return this.items_to_play_flat.length > 0;
        },

        is_playing: function () {
            return this.current_item && this.current_item.is_playing;
        }

    },
    watch: {
        is_playing: function (new_value, old_value) {
            if (DEBUG) console.log('is_playing changed', new_value, old_value, this.current_item);
            this.update_global_player_state(this.current_item);
        },
        current_item: function (new_value, old_value) {
            if (DEBUG) console.log('current_item changed', new_value, old_value, this.current_item);
            this.update_global_player_state(this.current_item);
        },
    },
    filters: template_filters,
    mounted: function () {

        store.dispatch('player/reset_current_items');

        this.initialize_player();

        $(window).on('unload', (e) => {
            if (DEBUG) console.debug('window unload');
        });

        // $(document).on('keydown', (e) => {
        //     console.debug(e)
        // });

        /**********************************************************
         * action handling from elements *outside* player app
         **********************************************************/
        $(document).on('click', '[data-player-action]', (e) => {

            e.preventDefault();

            const el = $(e.currentTarget);
            const action = el.data('player-action');
            const ct = el.data('player-ct');
            const uuid = el.data('player-uuid');
            const mode = el.data('player-mode');
            const offset = el.data('player-offset') || 0;

            // player *could* load multiple items at once.
            // in this case we send a single item (wrapped in list).
            const items = [{
                ct: ct,
                uuid: uuid
            }];

            const payload = {
                action: action,
                opts: {
                    mode: mode,
                    offset: offset
                },
                items: items
            };

            this.handle_action(payload);

        });

        $(document).on('content:changed', (e) => {
            this.update_global_player_state(this.current_item);
        })
    },

    methods: {

        /**********************************************************
         * vuex/store mutations
         **********************************************************/
        update_items_to_play: (payload) => store.commit('player/update_items_to_play', payload),

        /**********************************************************
         * vuex/store actions
         **********************************************************/
        _load_items_to_play: (payload) => store.dispatch('player/load_items_to_play', payload),

        /**********************************************************
         * initialize player backend
         **********************************************************/
        initialize_player: function () {
            if (DEBUG) console.debug('initialize_player');
            soundManager.setup({
                forceUseGlobalHTML5Audio: true,
                html5PollingInterval: 100,
                debugMode: DEBUG,
                onready: () => {
                    this.player = soundManager.createSound({
                        multiShot: false,
                        id: 'player_app_player',
                    });
                },
            });
        },
        /**********************************************************
         * expand/collapse playlist & details
         **********************************************************/
        toggle_expanded: function (e) {
            this.expanded = !this.expanded;
        },
        /**********************************************************
         * 'handle'  player actions
         **********************************************************/
        handle_action: function (payload) {
            if (DEBUG) console.debug('handle_action', payload);

            /******************************************************
             * load items to play from player API endpoint
             ******************************************************/
            const load = (payload) => {
                if (DEBUG) console.debug('load', payload);

                // TODO: ugly way. don't load track if already in player...
                if(payload && payload.items.length === 1 && payload.items[0].ct === 'catalog.media') {
                    const _c = this.items_to_play_flat.filter((i) => i.media.uuid === payload.items[0].uuid);
                    if(_c.length > 0) {
                        if (DEBUG) console.log(_c);
                        this.handle_action({
                            action: 'play',
                            item: _c[0]
                        });
                        return;
                    }
                }

                store.dispatch('player/load_items_to_play', payload).then(response => {
                    if (DEBUG) console.log('successfully loaded items:', response);
                    if (payload.opts.mode === 'replace') {
                        this.handle_action({
                            action: 'play',
                            item: this.items_to_play[0].items[payload.opts.offset || 0]
                        });
                    }
                }, error => {
                    if (DEBUG) console.error('error loading items:', error)
                })
            };

            /******************************************************
             * load items to play from player API endpoint
             ******************************************************/
            const play = (payload, args) => {
                if (DEBUG) console.debug('play', payload);

                if (this.player.paused) {
                    this.player.resume();
                } else {
                    this.player.pause();
                }

                const item = payload.item;
                if (!item) {
                    if (DEBUG) console.log('nothing to play...');
                    return;
                }
                item.is_current = true;

                const opts = {
                    url: item.media.assets.stream,
                    onplay: () => {
                        item.is_playing = true;
                    },
                    onstop: () => {
                        item.is_playing = false;
                    },
                    whileplaying: () => {
                        // TODO: hack - assume browser can autoplay if playing...
                        // if (!this.can_autoplay && this.player.position > 10) {
                        //     this.can_autoplay = true;
                        // }

                        item.playhead_position = Math.round(
                            this.player.position / parseFloat(item.media.duration * 1000) * 10000
                        ) / 100;
                        item.playhead_position_ms = this.player.position;
                        item.is_buffering = this.player.isBuffering;

                        if (!item.is_playing) {
                            item.is_playing = true;
                        }

                        // console.debug(item.playhead_position)

                        // console.debug('playing:', item.media.ct, item.media.uuid)

                    },
                    onfinish: () => {
                        if (DEBUG) console.log('onfinish');
                        this.player_play_offset(1, item);
                    },
                    onload: (success) => {
                        if (DEBUG) console.log('onload:', success)
                    },
                    onerror: (error, info) => {
                        console.error('onerror:', error, info);
                    }
                };

                this.player.play(opts);


                if (args && args.seek) {

                    console.log('SEEK:', args.seek, item.duration);

                    this.player.setPosition(item.duration / 100 * args.seek);
                } else {
                    this.player.setPosition(0);
                }

                // store.dispatch('set_current_item', item);


                this.set_current_item(item)

            };

            const play_first = () => {
                if (this.has_items_to_play) {
                    if (DEBUG) console.log(this.items_to_play[0].items[0]);
                    const payload = {
                        action: 'play',
                        item: this.items_to_play_flat[0]
                    };
                    play(payload);
                }
            };

            const pause = (payload) => {
                if(payload && payload.item) {
                    payload.item.is_playing = false;
                } else {
                    this.current_item.is_playing = false;
                }

                this.player.pause();
            };

            const resume = (payload) => {
                if (this.player.paused) {
                    this.player.resume();
                } else {
                    play(payload);
                }
            };

            const seek = (payload) => {
                if (DEBUG) console.log('position:', this.player.duration, 'value:', payload.value);

                if (payload.item === this.current_item) {
                    // seek in curent item
                    this.player.setPosition(this.player.duration / 100 * payload.value);
                    if (this.player.paused) {
                        this.player.resume();
                    } else {
                        play(payload, {seek: payload.value});
                    }
                } else {
                    // seek in other item
                    play(payload, {seek: payload.value});
                }
            };


            switch (payload.action) {
                case 'load':
                    load(payload);
                    break;
                case 'play':
                    play(payload);
                    break;
                case 'play_first':
                    play_first(payload);
                    break;
                case 'pause':
                    pause(payload);
                    break;
                case 'resume':
                    resume(payload);
                    break;
                case 'seek':
                    seek(payload);
                    break;
                default:
                    console.warn('nothong to do for:', payload.action)
            }

        },


        /**********************************************************
         * testing / debug....
         **********************************************************/
        set_current_item: function (item) {
            store.dispatch('player/set_current_item_key', item.key);
        },
        player_controls: function (action, item, value) {
            if (DEBUG) console.debug('player_controls:', action, item, value);

            this.handle_action({
                action: action,
                value: value === undefined ? null : value,
                item: item
            })
        },
        // TODO: ugly way...
        player_play_offset: function (offset, media) {
            let all_media = [];

            console.debug('player_play_offset', offset, media);

            this.items_to_play.forEach((item_to_play) => {
                item_to_play.items.forEach((media) => {
                    all_media.push(media)
                });
            });
            let index = all_media.findIndex((element) => element.key === media.key) + offset;
            if (index < 0 || index > (all_media.length - 1)) {
                this.player.stop();
                console.warn('player_play_offset index not available', index);
                this.player.stop();
                if (this.current_item) {
                    media.is_current = false;
                    this.current_item.is_current = false;
                }

                this.update_global_player_state();

                return;
            }
            if (DEBUG) console.log('player_get_next_sound', index);
            this.handle_action({
                action: 'play',
                value: null,
                item: all_media[index]
            })
        },


        /**********************************************************
         * load items from API
         **********************************************************/
        load_items_to_play: function (payload) {
            if (DEBUG) console.debug('load_items_to_play', payload);
            this.loading = true;
            APIClient.put(payload.url)
                .then((response) => {
                    if (DEBUG) console.debug(response.data);
                    this.items_to_play = pre_process_loaded_items(
                        [response.data]
                    );

                    // TODO: just temporary...
                    this.current_item = this.items_to_play[0].items[1];

                    this.loading = false;
                }, (error) => {
                    if (DEBUG) console.error('Player - error loading item', error);
                    this.loading = false;
                });
        },


        /**********************************************************
         * hackish - update non-vue shizzle
         **********************************************************/
        update_global_player_state: function(item) {
            if (DEBUG) console.log('update_global_player_state', item);
            console.log('update_global_player_state', item);

            const _items = $('.list-item--media');

            if(!item || !item.media) {
                console.log('looks like nothing is in the player...');
                _items.removeClass('is-current');
                _items.removeClass('is-buffering');
                _items.removeClass('is-playing');
                return;
            }

            const _current_item = $(`[data-uuid="${item.media.uuid}"]`);

            _items.removeClass('is-current');
            _items.removeClass('is-buffering');
            _items.removeClass('is-playing');

            _current_item.addClass('is-current');

            if(item.is_buffering) {
                _current_item.addClass('is-buffering');
            }

            if(item.is_playing) {
                _current_item.addClass('is-playing');
            }


        }


    }
});

module.exports = PlayerApp;
