
$(document).ready(function () {

    /*
    $.getScript('//connect.facebook.net/en_US/sdk.js', function () {
        FB.init({
            appId: document.settings.fb_app_id,
            version: 'v2.8'
        });

        FB.getLoginStatus(function(a, b, c){
            console.log(a, b, c)
        });

    });
    */
});
