<script>
    import {template_filters} from '../../../utils/template-filters';
    import {interpolate_array} from '../../../utils/interpolate-array';
    import APIClient from '../../../api/client';

    const DEBUG = true;

    export default {
        props: [
            'item',
            'include_waveform',
        ],
        data() {
            return {
                display_width: 500,
                loading: null,
                peaks: null,
                seek_position: 0
            }
        },
        mounted: function () {
            if (this.include_waveform) {
                this.load_peaks();
            }
        },
        watch: {
            item: function (val) {
                if (this.include_waveform) {
                    this.load_peaks();
                }
            },
        },
        computed: {
            position: function () {
                return (this.item.playhead_position > 0.3) ? this.item.playhead_position - 0.3 : 0;
            },
            position_peak: function () {
                return Math.floor(this.display_width / 100 / 2 * this.position);
            },
            reduced_peaks: function () {
                if (!this.peaks) {
                    return new Array(this.display_width / 2).fill(25);
                    //return null;
                }
                return interpolate_array(this.peaks, this.display_width / 2).map((el) => Math.floor(el)).map((el) => el < 4 ? 4 : el);

            }
        },
        methods: {
            seek: function (e) {
                const p = (e.offsetX / $(e.srcElement).parents('div').width()) * 100;
                this.$emit('seek', this.item, p);
            },
            seek_enter: function (e) {
                document.removeEventListener('mousemove', this.seek_move);
                document.addEventListener('mousemove', this.seek_move);
            },
            seek_leave: function (e) {
                document.removeEventListener('mousemove', this.seek_move);
            },
            seek_move: function (e) {
                //console.debug('seek_move', e);
                let _b = this.$refs.progress.getBoundingClientRect();
                let _x = Math.round(e.clientX - _b.x);
                this.seek_position = _x / _b.width * 100;
            },
            load_peaks: function () {
                console.debug('this.item.media.features ', this.item.media.features );
                if (this.item.media.features === undefined || !this.item.media.features) {
                    this.peaks = null;
                    return
                }
                this.loading = true;

                console.warn('asset url', this.item.media.features);

                APIClient.get(this.item.media.features)
                    .then((response) => {
                        if (DEBUG) console.debug(response.data);
                        this.peaks = response.data.peaks;
                        this.loading = false;
                    }, (error) => {
                        if (DEBUG) console.error('Player - error loading item', error);
                        this.loading = false;
                    });
            }
        },
        filters: template_filters
    }
</script>

<style lang="scss" scoped>

    @import "abstracts/variables";

    .playhead {
        cursor: crosshair;
        position: relative;
        height: 100%;
        //background: rgba(0, 191, 255, 0.16);

        &.has-waveform {
            background: 0;
        }

        &.is-playing {
            //background: #1919ff;
            .progress-container {
                background: #c2c2c2;
            }
        }
        .time-container {
            //background: white;
            padding: 2px;
            line-height: 10px;
            font-size: 12px;
            position: absolute;
            right: 0;
            top: -9px;
        }
        .progress-container {
            position: absolute;
            width: 100%;
            height: 4px;
            top: 44%;
            z-index: 99;
            background: #eeeeee;

            .peaks {
                height: 100%;
                top: 0;
                left: 0;
                width: 100%;
                position: absolute;
                pointer-events: none;
                //clear: both;
                display: flex;
                align-items: center;
                z-index: 100;
                //justify-content: center;
                .peak {
                    //float: left;
                    background: #b5b5b5;
                    background: rgba(0, 0, 0, 0.99);
                    width: 1px;
                    margin-right: 1px;

                    &.is-played {
                        background: $black;
                        background: $primary-color;
                        // background: $white;
                    }
                }
            }

            .progress-indicator {
                height: 100%;
                top: 0;
                left: 0;
                width: 0;
                position: absolute;
//                border-right: 1px solid $tertiary-color;
                border-right: 1px solid $primary-color;
                background: rgba(#1919ff, 0.5);
                z-index: 101;
            }
        }

        &.has-waveform {
            .progress-container {
                height: 100%;
                background: 0;
                top: 0;
                .progress-indicator {
                    background: 0;
                }
            ;
            }
        }
        .seek-container {
            //background: rgba(255, 165, 0, 0.05);
            border-right: 1px solid $primary-color;
            position: absolute;
            top: 0;
            height: 100%;
            width: 0%;
            z-index: 102;
            display: none;
        }
        &:hover {
            .seek-container {
                display: block;
            }
        }
    }

    // peak/waveform animations
    .peaks {
        .peak {
            transition: all 200ms ease-in-out;
            transition-property: height, background;

            &:nth-child(1) {
                transition-delay: 2s;
            }
            &:nth-child(2) {
                // transition-delay: 4s;
            }

            @for $i from 1 through 250 {
                &:nth-child(#{$i}) {
                    transition-delay: #{$i * 0.5}ms;
                }
            }
        }
    }


    svg polyline {
        shape-rendering: geometricPrecision;
    }

</style>

<template>
    <div ref="playhead" @click="seek($event)" v-on:mouseover="seek_enter(item, $event)"
         v-on:mouseleave="seek_leave(item, $event)" class="playhead"
         v-bind:class="{ 'is-playing': item.is_playing, 'is-current': item.is_current, 'has-waveform': include_waveform }">
        <!---->
        <div v-if="(! include_waveform)" class="time-container">
            <small v-if="item.is_buffering">buff</small>
            <small v-if="(! item.is_buffering && item.is_playing)">{{ item.playhead_position_ms | ms_to_time }}</small>
            <small>{{ item.media.duration | s_to_time }}</small>
        </div>

        <div ref="progress" class="progress-container">
            <div v-if="(include_waveform && reduced_peaks)" class="peaks">
                <div
                    v-for="(peak, index) in reduced_peaks"
                    class="peak"
                    v-bind:class="{ 'is-played': (index < position_peak) }"
                    v-bind:style="{ height: peak + '%' }">
                </div>
            </div>
            <div v-if="item.is_current" class="progress-indicator" v-bind:style="{ width: position + '%' }"></div>
        </div>
        <div class="seek-container" v-bind:style="{ width: seek_position + '%' }"></div>
    </div>
</template>
