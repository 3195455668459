
class CardUI {

    constructor(opts) {
        $(document).on('click', '.card .card__visual', (e) => {
            // this.visit();
            if(e.target.classList.contains('mi')) {
                return;
            }
            const container = $(e.currentTarget).parents('[data-absolute-url]');
            if(! container.length) {
                return;
            }

            const url = container.data('absolute-url');
            console.debug('url', url);
            Turbolinks.visit(url);
        });
    };
}

module.exports = CardUI;
