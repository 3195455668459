/**********************************************************************
 * AppInitializer handles setup (re-)initialization of
 * our mixed app stack. Some apps are global (like search) others
 * 'local' - like chat.
 * so chat has only to be initialized in chat context.
 * further we need to re-initialize apps on document reload -
 * e.g. form POST
 *
 * setup cacle has to run on both document.ready and XHR based
 * navigation via turbolinks
 **********************************************************************/

import Vue from 'vue';
import store from './store/index';
// apps
import ImageEditor from './apps/image-editor.vue'
import PlaylistEditor from './apps/playlist-editor.vue'
import CollectorApp from './apps/collector/collector-app.vue';
import AccountApp from './apps/account-app.vue';
import PlayerApp from './apps/player/player-app.vue';
import SearchApp from './apps/search/search-app.vue';
// helpers
import XHRForm from './components/xhr-form.js';
import ImageLoader from './components/image-loader.js';
import EndlessPaginator from './components/endless-paginator.js'
import ContextMenuApp from './components/context-menu.js'
import ListFilter from './components/list-filter.js'
import Lightbox from './components/lightbox.js'
import CardUI from './components/card-ui.js'
// shims
import ObjectRatingShim from './shims/object-rating.js'



import VueTranslate from 'vue-translate-plugin';

Vue.use(VueTranslate);

const DEBUG = false;

class AppInitializer {

    constructor(opts) {
        if (DEBUG) console.debug('AppInitializer - constructor');
        this.apps = [];
        this.shims = [];
        this.bindings();
        this.setup_apps();
    };

    bindings() {
        if (DEBUG) console.debug('AppInitializer - bindings');
        $(document).on('content:changed', (e) => {
            if (DEBUG) console.debug('AppInitializer: page change');
            this.setup_apps();
        });
    };

    setup_apps() {
        if (DEBUG) console.debug('AppInitializer - setup_apps');

        if ($('#image_editor').length) {
            const image_editor = new Vue({
                el: '#image_editor',
                render: h => h(ImageEditor, {
                    props: {
                        search_scope: 'bar'
                    }
                })
            });
            this.apps['ImageEditor'] = image_editor;
        }

        if ($('#playlist_editor').length) {
            const playlist_editor = new Vue({
                el: '#playlist_editor',
                render: h => h(PlaylistEditor, {
                    props: {
                        search_scope: 'bar'
                    }
                })
            });
            this.apps['PlaylistEditor'] = playlist_editor;
        }

        /**************************************************************
         * Collector App
         * passing props:
         * https://forum.vuejs.org/t/passing-props-to-root-instances-in-2-0/244/12
         **************************************************************/
        const collector_app_container = document.getElementById('collector_app');
        if (collector_app_container) {

            const CollectorComponent = Vue.extend(CollectorApp);

            this.apps['CollectorApp'] = new CollectorComponent({
                el: collector_app_container,
                propsData: collector_app_container.dataset
            });
        }

        /**************************************************************
         * Player App
         * passing props:
         * https://forum.vuejs.org/t/passing-props-to-root-instances-in-2-0/244/12
         **************************************************************/
        const player_app_container = document.getElementById('player_app_next');
        if (player_app_container) {
            const PlayerAppComponent = Vue.extend(PlayerApp);
            this.apps['PlayerApp'] = new PlayerAppComponent({
                el: player_app_container,
                store
            });
        }

        /**************************************************************
         * Search App
         * passing props:
         * https://forum.vuejs.org/t/passing-props-to-root-instances-in-2-0/244/12
         **************************************************************/
        const search_app_container = document.getElementById('search_app');
        if (search_app_container) {
            const SearchAppAppComponent = Vue.extend(SearchApp);
            this.apps['PlayerApp'] = new SearchAppAppComponent({
                el: search_app_container
            });
        }

        /**************************************************************
         * Account App
         **************************************************************/
        const account_app_container = document.getElementById('account_app');
        if (account_app_container) {

            const AccountComponent = Vue.extend(AccountApp);

            this.apps['AccountApp'] = new AccountComponent({
                el: account_app_container,
                propsData: account_app_container.dataset
            });
        }


        // helpers (one time setup)
        if(this.apps['XHRForm'] === undefined) {
            this.apps['XHRForm'] = new XHRForm();
        }

        if(this.apps['ImageLoader'] === undefined) {
            this.apps['ImageLoader'] = new ImageLoader();
        }

        if (this.apps['EndlessPaginator'] === undefined) {
            this.apps['EndlessPaginator'] = new EndlessPaginator();
        }

        if (this.apps['ContextMenuApp'] === undefined) {
            this.apps['ContextMenuApp'] = new ContextMenuApp({});
        }

        if (this.apps['ListFilter'] === undefined) {
            this.apps['ListFilter'] = new ListFilter();
        }

        if (this.apps['Lightbox'] === undefined) {
            this.apps['Lightbox'] = new Lightbox();
        }

        if (this.apps['CardUI'] === undefined) {
            this.apps['CardUI'] = new CardUI();
        }


        // shims (one time setup)
        if(this.shims['ObjectRatingShim'] === undefined) {
            this.shims['ObjectRatingShim'] = new ObjectRatingShim();
        }

    };
}

module.exports = AppInitializer;
