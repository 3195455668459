;

require('soundmanager2');

import Vue from 'vue';
import APIClient from '../api/client';
import store from '../store';


let PlayerApp = function (options) {

    let self = this;
    this.debug = true;
    //this.store = store2.namespace('player');
    this.playlist = [];

    // holds the uuids of currently playing/loaded media object
    this.now_playing = null;

    this.backend = {


        player: null,
        current_sound: null,
        whileplaying: function () {

            //console.log(this)
            let p = Math.round((this.position / this.duration) * 100000) / 100000;

            let playhead_handler_position = p * 98 + 1;
            // if(playhead_handler_position < 1) {
            //     playhead_handler_position = 1;
            // }

            self.backend.current_sound = $.extend(
                self.backend.current_sound,
                {
                    playhead_position: p * 100,
                    playhead_handler_position: playhead_handler_position,
                    playhead_abs_time: this.position
                }
            );
            let duration = self.backend.current_sound.duration * 1000;
            self.vm.buffered = buffer_to_percent(this.buffered, duration);

        },
        onbufferchange: function () {

        },
        onfinish: function () {
            self.handle_skip(1);
        }
    };

    this.init = function (options) {

        //console.debug(options);


        if($('#player_app').length < 1) {
            console.warn('no container for player app available');
            return;
        }

        // initialize soundmanager
        soundManager.setup({
            preferFlash: false,
            debugMode: false,
            onready: function () {
                self.backend.player = soundManager.createSound({
                    id: 'player_backend',
                    whileplaying: self.backend.whileplaying,
                    onbufferchange: self.backend.onbufferchange,
                    onfinish: self.backend.onfinish
                });

            }
        });

        // initialize vue app
        self.vm = new Vue({
            el: '#player_app',
            data: {
                playlist: [],
                current_sound: null,
                notifications: [],
                num_unseen: 0,
                latest_notification: null,
                is_active: false,
                playlist_expanded: false,
                buffered: [],
                rating_expanded: false,
                //settings: self.settings
            },

            computed: {

                // testing store
                count: () => store.state.count,
                settings: () => store.state.settings,

                playing_now: function () {
                    if (this.playlist.length && this.current_sound) {


                        let current_sound = this.current_sound;
                        let index = 0;

                        $.each(this.playlist, function (i, el) {
                            if (el.uuid == current_sound.uuid) {
                                index = i;
                            }
                        });

                        return this.playlist[index]
                    }
                    return false
                },

                state: function () {

                    if (this.current_sound) {

                        return {
                            play_state: this.current_sound.play_state,
                            data_state: this.current_sound.data_state
                        }

                    }

                    return {
                        play_state: 'init',
                        data_state: 'init'
                    }
                },

                container_state: function () {
                    return {
                        active: this.is_active
                    }
                },

                playlist_is_visible: function () {
                    return this.playlist_expanded
                }
            },

            methods: {

                update_settings: (key, value) => store.commit('update_settings', {key: key, value: value}),

                handle_media: function (item, action, e) {

                    if (action === 'play') {
                        self.handle_play(item);
                    }

                    if (action === 'remove') {
                        self.handle_remove(item);
                    }

                    if (action === 'pause') {
                        self.backend.player.pause();
                        item.play_state = 'paused';
                        item.playing = false;
                    }

                    if (action === 'seek') {

                        let p = (e.offsetX / $(e.srcElement).parents('div').width());
                        let duration = item.duration * 1000;

                        self.backend.player.stop().setPosition(duration * p).play();

                    }

                },

                play_previous: function (e) {
                    self.handle_skip(-1);
                },

                play_next: function (e) {
                    self.handle_skip(1);
                },

                toggle_playlist_expanded: function(e) {
                    e.stopPropagation();
                    this.playlist_expanded = !this.playlist_expanded;

                    if(this.playlist_expanded) {
                        $('body').addClass('player-playlist-expanded');
                    } else {
                        $('body').removeClass('player-playlist-expanded');
                    }

                    $('body').data('playlist_expanded', this.playlist_expanded);
                },


                /******************************************************
                 * waveform / playhead handling
                 ******************************************************/
                update_playhead_canvas: function() {

                    if(!this.current_sound) {
                        return;
                    }

                    if (!this.current_sound.waveform) {
                        console.log('canvas: no waveform data');
                        return;
                    }

                    let waveform_canvas = $('#waveform_canvas');
                    if(!waveform_canvas.length) {
                        console.log('no waveform-canvas found');
                        return;
                    }

                    update_playhead_canvas(waveform_canvas, this.current_sound);

                },
                update_playlist_canvas: function(val, oldVal) {

                    if(!this.playlist_expanded) {
                        // no need to update canvas if not visible :)
                        return;
                    }

                    // console.log('update_playlist_canvas', val, oldVal);

                    if(val === undefined || oldVal === undefined) {
                        return;
                    }

                    // TODO: kind of ugly. loops current & old to check if changed
                    // does not work this way: https://github.com/vuejs/vue/issues/2649
                    for(let i=0; i<val.length; i++) {

                        let item = val[i];
                        let old_item = oldVal[i];

                        let container = $('[data-uuid=' + item.uuid + ']');

                        let waveform_canvas = container.find('.waveform-canvas');

                        if(!waveform_canvas.length) {
                            //console.log('no waveform-canvas found');
                            return;
                        }

                        console.log('playlist_canvas:', waveform_canvas, item)

                        //update_playhead_canvas(waveform_canvas, item);

                    }

                },

                /******************************************************
                 * handle rating
                 ******************************************************/
                // show_rating_panel: function(e) {
                //     e.stopPropagation();
                //     this.rating_expanded = true;
                // },
                // hide_rating_panel: debounce(function (e) {
                //     this.rating_expanded = false;
                // }, 0),

            },
            mounted: function (){
                this.update_playhead_canvas();
            },
            watch: {
                'current_sound': {
                    handler: function (val, oldVal) {
                        this.update_playhead_canvas();
                        self.update_global_now_playing(val, oldVal);
                    },
                    deep: true
                },
                'playlist': {
                    handler: function (val, oldVal) {
                        this.update_playlist_canvas(val, oldVal);
                    },
                    immediate: true,
                    deep: true
                }
            },
            filters: {
                sec_to_time: function (value) {
                    return ms_to_time(value * 1000)
                },
                ms_to_time: function (value) {
                    return ms_to_time(value)
                }
            }

        });

        // self.vm.buffered = self.backend.player.buffered;

        // load eventual player data
        // TODO: handle propper state save & load
        //self.load_state();

        self.bindings();
    };


    this.load_playlist = function (opts) {

        //self.vm.playlist = [];

        // const url = '/api/player/play/200da3de-e66d-41a0-b57f-5c4747d3cc72/';

        APIClient.put(opts.url)
            .then((response) => {
                console.log(response.data);

                let items = [];

                $.each(response.data.items, function (i, item) {

                    $.extend(item.media, {
                        data_state: 'init',
                        play_state: 'stopped',
                        playing: false,
                        playhead_position: 0,
                        release_data: null,
                        waveform_data: []
                    });

                    items.push(item.media);

                    //self.process_playlist_item(item.media);

                });


                if (opts.mode && opts.mode === 'queue') {
                    self.vm.playlist = self.vm.playlist.concat(items);
                } else {

                    let offset = parseInt(opts.offset) || 0;
                    self.vm.playlist = items;
                    self.vm.handle_media(self.vm.playlist[offset], 'play')

                }


            }, (error) => {
                console.error('Player - error loading item', error)
            });


    };


    this.process_playlist_item = function (item) {

        console.debug('processing playlist item:', item);

        if(item.data_state === 'loaded') {
            console.log('already loaded');
            return;
        }

        // complete release data
        $.ajax(item.release, {
            dataType: 'json',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('Authorization', 'Token ' + '65088b87e0b99f1ba6e77e1bed5afda529b96bd7');
            }
        }).then(function (release) {
            //console.debug(release);

            item.release_data = release;
            item.data_state = 'loaded';

        });

        // complete waveform data
        if(item.waveform) {
            $.ajax(item.waveform, {
                dataType: 'json',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', 'Token ' + '65088b87e0b99f1ba6e77e1bed5afda529b96bd7');
                }
            }).then(function (waveform) {
                //console.debug('waveform data:', waveform);
                item.waveform_data = waveform.data;
            });
        }

    };


    this.bindings = function () {

        // pass event to on_ready
        $(document).on('content:changed', function (e) {
            // self.on_ready(e);
        });

        $(window).on('unload', function () {
            console.debug('window unload');
            self.safe_state();
        });

        // action handling outside player app
        $(document).on('click', '[data-player-action]', function (e) {

            e.preventDefault();

            let action = $(this).data('player-action');
            let url = $(this).data('player-url');
            let offset = $(this).data('player-offset');

            self.load_playlist({
                url: url,
                mode: action,
                offset: offset
            })

        });


        // handle now playing.
        // TODO: likely this should be placed outside the player app itself...
        $(document).on('player:now_playing', function(e, now_playing) {

            //console.log('now_playing', now_playing);

            $('[data-uuid]').removeClass('playing paused');

            $('[data-uuid="' + now_playing.media + '"]').addClass(now_playing.state);
            $('[data-uuid="' + now_playing.artist + '"]').addClass(now_playing.state);
            $('[data-uuid="' + now_playing.release + '"]').addClass(now_playing.state);


        });

    };






    ///////////////////////////////////////////////////////////////////
    // player controls
    ///////////////////////////////////////////////////////////////////


    this.handle_play = function (item) {

        if(item === undefined) {
            return;
        }

        console.debug('handle_play', item);


        self.backend.current_sound = item;
        self.vm.current_sound = item;


        if (item.play_state === 'paused') {

            item.play_state = 'playing';
            item.playing = true;
            self.backend.player.resume();
            return;
        }

        let url = item.stream_url;

        self.backend.player.stop();
        //self.backend.player.setPosition(10000);
        self.backend.player.play({
            url: url
        });

        $.each(self.vm.playlist, function (i, el) {
            el.playing = false;
            el.play_state = 'stopped';
            el.playhead_position = 0;
        });

        item.play_state = 'playing';
        item.playing = true;


        self.process_playlist_item(item);

    };


    this.handle_remove = function (item) {

        console.debug('handle_remove', item);

        let current_index = playlist_index_by_uuid(self.vm.playlist, item.uuid);


        self.vm.playlist.splice(current_index, 1);




    };

    this.handle_skip = function (offset) {

        let playlist = self.vm.playlist;
        let current_sound = self.backend.current_sound;

        let current_index = playlist_index_by_uuid(playlist, current_sound.uuid);

        let requested_index = (current_index + offset);


        //console.debug('handle_skip:', offset, 'current_index:', current_index, 'requested_index:', requested_index);

        if (requested_index < 0 || requested_index >= playlist.length) {
            console.warn('invalid index');
            return;
        }

        self.handle_play(playlist[requested_index]);


    };


    this.safe_state = function() {

        return;

        console.debug('save player state');

        let player_data = {
            playlist: self.vm.playlist,
            cs: self.vm.current_sound
        };

        self.store.set('data', player_data);

    };

    this.load_state = function() {

        return;

        let player_data = self.store.set('data');

        if(player_data) {
            console.debug('loaded player data:', player_data);
            if(player_data.playlist) {
                self.vm.playlist = player_data.playlist;
            }

            // self.vm.current_sound = player_data.cs;
            // self.backend.current_sound = player_data.cs;
            if(player_data.cs) {
                self.handle_play(player_data.cs);
                self.backend.player.setPosition(player_data.cs.playhead_abs_time);
            }
        }
    };



    this.update_global_now_playing = function(val, old_val) {

        let now_playing = {
            media: null,
            release: null,
            artist: null
        };

        // gather uuids
        if(val) {
            now_playing.media = val.uuid || null;
            now_playing.release = val.release_uuid || null;
            now_playing.artist = val.artist_uuid || null;
            now_playing.state = val.play_state || null;
        }

        //if(JSON.stringify(self.now_playing) !== JSON.stringify(now_playing)) {
            self.now_playing = now_playing;

            $(document).trigger('player:now_playing', [now_playing] );

        //}

    };



    return this.init(options);

};

module.exports = PlayerApp;


// helper functions
let buffer_to_percent = function (buffer, duration) {

    let _buffered = [];
    $.each(buffer, function (i, el) {
        _buffered.push({
            start: (el.start / duration) * 100,
            end: (el.end / duration) * 100
        });
    });
    return _buffered;
};


let playlist_index_by_uuid = function (arr, uuid) {

    let index = -1;
    $.each(arr, function (i, el) {
        if (el.uuid === uuid) {
            index = i;
        }
    });

    return index;
};


let ms_to_time = function (time) {

    if (time == undefined) {
        return '';
    }

    if (time == 0) {
        return '00:00:000';
    }

    time = Math.abs(time);

    let millis = time % 1000;
    time = parseInt(time / 1000);
    let seconds = time % 60;
    time = parseInt(time / 60);
    let minutes = time % 60;
    time = parseInt(time / 60);
    let hours = time % 24;
    let out = "";

    if (hours && hours > 0) {
        if (hours < 10) {
            out += '0';
        }
        out += hours + ':';
    } else {
        // out += '0' + ':';
    }

    if (minutes && minutes > 0) {
        if (minutes < 10) {
            out += '0';
        }
        out += minutes + ':';
    } else {
        out += '00' + ':';
    }

    if (seconds && seconds > 0) {
        if (seconds < 10) {
            out += '0';
        }
        out += seconds + '';
    } else {
        out += '00' + '';
    }

    return out.trim();
};





let update_playhead_canvas = function(el, item) {

    if(!item) {
        return;
    }

    let canvas = $(el)[0];

    let cx = canvas.getContext('2d');
    let width = canvas.width;
    let height = canvas.height;


    // if(!item.waveform_data) {
    //     console.log('canvas: no waveform data');
    //     return;
    // }

    // let step_x = Math.round(canvas.width / cs.waveform_data.length);
    let step_x = canvas.width / item.waveform.length;

    cx.clearRect(0, 0, canvas.width, canvas.height);
    cx.lineWidth = 1;

    $.each(item.waveform, function (i, value) {

        let x = (i) * step_x;
        let y1 = height - (value / 100 * height);
        let y2 = value / 100 * height;

        if((item.playhead_position * 2) <= i + 1) {
            cx.strokeStyle = "#ababab";

            if(!item.playing) {
                cx.strokeStyle = "#dcdcdc";
            }
        } else {
            cx.strokeStyle = "#6000FF";
        }

        cx.beginPath();
        cx.moveTo(x, y1);
        cx.lineTo(x, y2);
        cx.stroke();

    });

};
