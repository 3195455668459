<script>

    const DEBUG = true;

    export default {
        name: 'ObjectRating',
        props: {
            obj: {
              type: Object,
              default: null
            },
            size: {
              type: Number,
              default: 20
            },
            theme: {
                type: String,
                default: 'light',
                validator: function (value) {
                    return ['dark', 'light'].indexOf(value) !== -1
                }
            },
        },
        data() {
            return {
                isFullscreen: false,
                hoverValue: null
            }
        },
        mounted: function () {
            if (DEBUG) console.debug('ObjectRating - mounted');
            this.loadRating();

        },
        computed: {
            color() {
                return (this.theme === 'dark') ? '#fff' : '#000';
            },
            objCt() {
                return (this.obj) ? this.obj.ct : null;
            },
            objUuid() {
                return (this.obj) ? this.obj.uuid : null;
            },
            rating() {
                return this.$store.getters['rating/ratingByKey'](this.objCt, this.objUuid);
            },
            avgRating() {
                return (this.rating) ? this.rating.avg_rating : null;
            },
            userRating() {
                if(this.hoverValue) {
                    return null;
                }
                return (this.rating) ? this.rating.user_rating : null;
            },
            markedRating() {
                return (this.rating) ? this.rating.avg_rating : null;
            },
            hoveredRating() {
                return (this.hoverValue) ? this.hoverValue : null;
            },
            ratingOptions() {
                const options = [];
                for (const i of Array(5).keys()) {
                    options.push({
                        value: i + 1,
                        style: {
                            'is-marked': (this.markedRating && this.markedRating > i),
                            'is-avg': (this.avgRating === i + 1),
                            'is-user': (this.userRating === i + 1),
                            'is-hover': (this.hoveredRating > i),
                        }
                    })
                }
                return options;
            }
        },
        methods: {
            mouseover: function(value) {
                this.hoverValue = value;
            },
            mouseleave: function() {
                this.hoverValue = null;
            },
            rate: function(value) {
                if (DEBUG) console.debug('rate', value);
                this.$store.dispatch('rating/setRating', {objCt: this.objCt, objUuid: this.objUuid, payload: value });
            },
            loadRating: function (e) {
                if(this.rating) {
                    console.debug('rating already here...');
                    return;
                }
                this.$store.dispatch('rating/loadRating', {objCt: this.objCt, objUuid: this.objUuid});
            },
        },
    }
</script>

<style lang="scss" scoped>
    .rating {
        display: flex;
        .step {
            color: inherit;
            font-size: inherit;
            opacity: .2;
            cursor: pointer;
            .mi {
                font-size: inherit;
            }
            &.is-marked {
                opacity: 1;
            }
            &.is-user {
                opacity: 1;
                color: red;
            }
            &.is-hover {
                opacity: 1;
                color: red;
            }
        }
    }
</style>

<template>
    <div
        class="rating"
        :style="{ color: color, 'font-size': `${size}px` }"
        @mouseleave="mouseleave">
        <div
            class="step"
            v-for="option in ratingOptions"
            :class="option.style"
            @mouseover="mouseover(option.value)"
            @click="rate(option.value)">
            <i class="mi">
                favorite
            </i>
        </div>
    </div>
</template>
