// shim to use vue based `ObjectRating` with legacy JS / jQuery handling

// import store from "../store";
import store from '../store/index';
import Vue from 'vue';
import ObjectRating from '../components/rating/ObjectRating.vue';

const DEBUG = false;

class ObjectRatingShim {

    constructor(opts) {
        if (DEBUG) console.log('ObjectRatingShim: constructor');

        document.addEventListener("content:changed", (e) => {
            if (DEBUG) console.log('ObjectRatingShim: content:changed');
            this.processRatings();
        });

        this.processRatings();

    };

    processRatings() {
        if (DEBUG) console.log('ObjectRatingShim: processRatings');

        const selector = document.querySelectorAll('[data-object-rating]');

        [...selector].forEach((el) => {
            this.processRating(el);
        });

    };

    processRating(el) {
        if (DEBUG) console.log('ObjectRatingShim: processRating');

        new Vue({
            el: el,
            store,
            render: h => h(ObjectRating, {
                props: {
                    obj: {
                        ct: el.dataset.objectRatingCt,
                        uuid: el.dataset.objectRatingUuid,
                    },
                    size: parseInt(el.dataset.objectRatingSize),
                    theme: el.dataset.objectRatingTheme,
                },
            })
        });


    };

}

module.exports = ObjectRatingShim;
