<script>

    const DEBUG = false;

    import Visual from '../../../components/visual.vue';
    import ObjectRating from '../../../components/rating/ObjectRating.vue';

    export default {
        name: 'PlayerDetailCurrent',
        components: {
            'visual': Visual,
            'rating': ObjectRating,
        },
        directives: {},
        props: {
            item: {
              type: Object,
              default: null
            },
        },
        data() {
            return {
                isFullscreen: false,
            }
        },
        mounted: function () {

        },
        computed: {
            settings() {
                // return this.$store.getters['scheduler/settings'];
            },
            imageUrl() {
                if(! (this.item && this.item.media)) {
                    return null;
                }
                return this.item.media.image;

            }
        },
        methods: {
            hide: function() {
                this.$emit('hide');
            },
        },
        watch: {

        },
    }
</script>
<style lang="scss" scoped>

    @import "abstracts/variables";
    @import "abstracts/mixins";

    .current {
        width: 100%;
        min-width: 100%;

        &__visual {
            display: flex;
            flex-direction: column;
            align-items: center;
            @include bp-below(sm) {
                height: 72px;
                figure {
                    max-height: 72px !important;
                    max-width: 72px !important;
                }
            }
        }

        &__actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 1rem;

        }

        &__metadata {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 1rem;
            line-height: 200%;

            .title {
                font-size: 130%;
            }

            color: #222;
            a {
                color: inherit;
                &:hover {
                    text-decoration: underline;
                }
            }
            span {
                opacity: .5;
            }
        }

    }

</style>

<template>
    <div class="current">
        <div class="current__visual">
            <visual
                :max-size="200"
                :url="imageUrl"></visual>
        </div>
        <div class="current__actions">
            <div
                class="rating"
                v-if="(item && item.media)">
                <rating
                    :size="20"
                    :obj="item.media"></rating>
            </div>
            <div class="more">
                M
            </div>
        </div>
        <div class="current__metadata">
            <p
                class="title"
                v-if="(item && item.media)">
                <a
                    @click="hide"
                    :href="item.media.detail_url">{{ item.media.name }}</a>
            </p>
            <p
                class="artist"
                v-if="(item && item.media)">
                <span>by</span>
                <a
                    @click="hide"
                    :href="item.media.artist_detail_url">{{ item.media.artist_display }}</a>
            </p>
            <p
                class="release"
                v-if="(item && item.media)">
                <a
                    @click="hide"
                    :href="item.media.release_detail_url">{{ item.media.release_display }}</a>
            </p>
            <p
                class="label"
                v-if="(item && item.media)">
                <span>on</span>
                <a
                    @click="hide"
                    :href="item.media.label_detail_url">{{ item.media.label_display }}</a>
            </p>
        </div>
    </div>
</template>
