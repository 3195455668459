import store from 'store';

const DEBUG = false;

class ListFilter {

    constructor() {

        this.store_key = 'ui-listfilter-expanded';
        this.expanded_filters = [];

        this.load_state();
        this.bindings();
    };

    bindings() {

        $(document).on('click', '[data-listfilter] .filter-header', (e) => {
            let el = $(e.currentTarget).parents('[data-listfilter]');
            let key = el.data('listfilter');
            this.toggle_filter(key);

        });
        // $(document).on('mouseover', '[data-listfilter]', (e) => {
        //     let el = $(e.currentTarget);
        //     let key = el.data('listfilter');
        //
        //     $('[data-listfilter]').not(`[data-listfilter="${key}"]`).removeClass('expanded');
        //     $(`[data-listfilter="${key}"]`).toggleClass('expanded');
        //
        // });
        // $(document).on('mouseout', '[data-listfilter]', (e) => {
        //     let el = $(e.currentTarget).parents('[data-listfilter]');
        //     let key = el.data('listfilter');
        //
        //     $(`[data-listfilter]`).removeClass('expanded');
        //
        // });



    };
    toggle_filter(key) {

        $('[data-listfilter]').not(`[data-listfilter="${key}"]`).removeClass('expanded');
        $(`[data-listfilter="${key}"]`).toggleClass('expanded');

        // let _expanded = this.expanded_filters;
        //
        // let index = _expanded.indexOf(key);
        //
        // if (index === -1) {
        //     _expanded.push(key);
        // } else {
        //     _expanded.splice(index, 1);
        // }
        //
        // this.expanded_filters = _expanded;
        // this.apply_state();
        // //this.save_state();

    };
    load_state() {
        this.expanded_filters = store.get(this.store_key, []);
        //this.apply_state();
    };
    save_state() {
        store.set(this.store_key, this.expanded_filters);
        //this.apply_state();
    };
    apply_state() {

        if (DEBUG) console.log('state:', this.expanded_filters);

        $('[data-listfilter]').each((i, item) => {
            let el = $(item);
            if(this.expanded_filters.includes(el.data('listfilter'))) {
                el.addClass('expanded');
            } else {
                el.removeClass('expanded');
            }

        });
    };

}

module.exports = ListFilter;
