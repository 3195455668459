import {WebSocketBridge} from 'django-channels';
import store from "../store";

const DEBUG = true;

class RatingApp {

    constructor(opts) {

        if (DEBUG) console.log('RatingApp: constructor');

        this.cached_ratings = [];
        this.wsb = new WebSocketBridge();
        //this.wsb.connect('ws://local.sonicsquirrel.net:8080/ws/rating/');
        this.wsb.connect(opts.ws_url + '/ws/rating/', null, {debug: DEBUG});

        // this.wsb.listen((data, stream) => this.update_item_display(data, stream));

        this.wsb.addEventListener("message", (event) => {
            console.log('RatingApp: rating from stream:', event.data);
            this.update_item_display(event.data);
        });



        this.user = opts.user || null;

        setTimeout(() => {
            console.debug('RatingApp: process ratings (ws connection)');
            this.process_ratings();
        }, 5000);

        this.bindings();
    };

    bindings() {

        $(document).on('content:changed paginate:complete', (e) => {
            console.debug('RatingApp: content:changed paginate:complete');
            this.process_ratings();
        });

        $(document).on('click', '[data-rating] a', (e, a, b, c) => {
            e.preventDefault();

            let value = $(e.currentTarget).data('rating-value');
            let el = $(e.currentTarget).parents('[data-rating]');

            this.wsb.send({ct: el.data('ct'), uuid: el.data('uuid'), value: value});

        });

    };

    process_ratings() {
        //console.log('RatingApp: process_ratings');
        $('[data-rating]').each((i, el) => {
            let _el = $(el);
            this.process_rating(_el, {ct: _el.data('ct'), uuid: _el.data('uuid')})
        })

    };

    process_rating(el, data) {

        let key = data.ct + ':' + data.uuid;

        //console.log('key', key);

        // check for cached data
        if (this.cached_ratings[key] !== undefined) {
            //console.debug('RatingApp: rating data from cache');
            this.update_item_display(this.cached_ratings[key]);
            return;
        }

        // request rating for object
        // console.debug('wsb data', data);
        this.wsb.send(data);

    };

    update_item_display(data) {

        //console.log('update_item_display', data);

        let key = data.ct + ':' + data.uuid;
        this.cached_ratings[key] = data;

        $('[data-rating][data-uuid="' + data.uuid + '"]').each((i, item) => {

            let el = $(item);

            //el.fadeOut(5000);

            let rating = data.rating;

            el.data('data-rating-avg', rating.avg_rating);
            el.data('data-rating-user', rating.user_rating);
            el.data('data-rating-num', rating.num_ratings);


            // TODO: not so nice - to update via css we need classes...
            el.removeClass('rating-avg-1 rating-avg-2 rating-avg-3 rating-avg-4 rating-avg-5 rating-user');
            el.addClass('rating-avg-' + Math.round(rating.avg_rating));

            if(rating.user_rating) {
                $('a', el).removeClass('rating-user');
                $('a[data-rating-value="' + rating.user_rating + '"]', el).addClass('rating-user');
            } else {
                $('a', el).removeClass('rating-user');
            }

            // add rating summary information
            let summary_container = el.find('[data-rating-summary]');
            let num_ratings_container = summary_container.find('[data-rating-summary-num-ratings]');
            let rating_summary_container = summary_container.find('[data-rating-summary-rating-avg]');

            if (rating.num_ratings && rating.avg_rating) {
                num_ratings_container.html(rating.num_ratings);
                rating_summary_container.html(rating.avg_rating);
            } else {
                num_ratings_container.html('');
                rating_summary_container.html('');
            }

        });



    };

}

module.exports.RatingApp = RatingApp;
