import Vue from "vue"
import Dropzone from 'dropzone';

let debounce = require('debounce');

let MediaEditorApp = function (options) {

    let self = this;
    this.debug = true;
    this.api_url = null;
    this.label_api_url = null;

    this.vm = null;
    this.images = [];

    this.init = function (options) {
        self.bindings();
        self.setup();
        self.click_bindings();
    };

    this.bindings = function () {
        // rebinding handlers
        $(document).on('content:changed', function (e) {
            self.setup();
            self.click_bindings();
        });
    };

    this.setup = function () {

        let container = $('#media_editor');

        if (container.length < 1) {
            if (self.debug) {
                // console.debug('MediaEditorApp - no container for media editor app available');
            }
            return;
        }

        self.api_url = container.data('resource_uri');
        self.label_api_url = container.data('label_resource_uri');

        //alert(resource_uri);

        // initialize vue app
        self.vm = new Vue({
            el: '#media_editor',
            data: {
                locked: false,
                fs_data: null,
                dz_uploader: null,
                files: [],
                media_set: []
            },
            // components: {
            //     Autocomplete
            // },
            methods: {
                update_media_name: debounce(function (media, e) {
                    console.debug(media.name);
                    media.name = e.target.value;
                }, 100),
                update_media_tracknumber: debounce(function (media, e) {
                    console.debug(media.tracknumber);
                    media.tracknumber = e.target.value;
                }, 100),

                /******************************************************
                 FTP browser handling
                 ******************************************************/
                load_fs_browser: function (e) {
                    console.log('load_fs_browser');

                    // only have one option at a time
                    this.unload_dz_uploader();

                    // act as toggle
                    if(this.fs_data) {
                        this.unload_fs_browser();
                        return;
                    }

                    this.refresh_fs_browser();

                },
                refresh_fs_browser: function (e) {
                    console.log('refresh_fs_browser');

                    // handle autocomplete
                    //let fs_url = '/api/contentmanager/label/0b610ca5-38fa-b459-2671-c0df6115cd19/fs-browser/';
                    let fs_url = self.label_api_url + 'fs-browser/';
                    $.ajax(fs_url, {
                        type: 'GET',
                        dataType: 'json',
                        contentType: 'application/json',
                        async: false
                    }).done(function (data) {
                        console.log('fs:', data);
                        self.vm.fs_data = data;
                    });

                    // self.vm.$forceUpdate();


                },
                unload_fs_browser: function (e) {
                    console.log('unload_fs_browser');
                    this.fs_data = null;
                    // self.vm.$forceUpdate();
                },
                /******************************************************
                 TODO: fs browser directory selection
                 ******************************************************/
                fs_browser_toggle_directory: function (directory, e) {
                    console.log('fs_browser_toggle_directory', directory);
                    directory.expanded = directory.expanded !== true;
                    self.vm.$forceUpdate();
                },
                fs_browser_import_directory: function (directory, e) {
                    console.log('fs_browser_import_directory', directory);

                    // finally run directory import

                    // TODO: implement nicer...
                    if (directory.locked) {
                        if (!confirm('This folder is already assigned to one of your releases. Are you sure to re-import it another time?')) {
                            return;
                        }
                    }

                    // handle autocomplete
                    let fs_url = self.api_url + 'fs-import-directory/';

                    let data = {
                        directory: directory
                    };

                    self.vm.locked = true;

                    $.ajax(fs_url, {
                        type: 'POST',
                        dataType: 'json',
                        contentType: 'application/json',
                        data: JSON.stringify(data),
                        async: true
                    }).done(function (data) {
                        console.log('scan:', data);
                        //self.vm.fs_data = data;

                        self.vm.media_set = data.media_set;
                        self.vm.locked = false;
                        self.vm.fs_data = null;
                        self.vm.$forceUpdate();

                    });


                },

                /******************************************************
                 dropzone upload handling
                 ******************************************************/
                load_dz_uploader: function (e) {
                    console.log('load_dz_uploader');
                    // self.vm.$forceUpdate();

                    // act as toggle
                    if(this.dz_uploader) {
                        this.unload_dz_uploader();
                        return;
                    }

                    // only have one option at a time
                    this.unload_fs_browser();

                    this.dz_uploader = null;


                    this.dz_uploader = true;

                    setTimeout(function(){
                        self.setup_dropzone();
                    }, 10);



                    this.dz_uploader = function() {
                        return 'z';
                    }
                },
                unload_dz_uploader: function (e) {
                    console.log('unload_dz_uploader');
                    this.dz_uploader = null;
                },


                /******************************************************
                 TODO: just a temporary implementation
                 ******************************************************/
                update_media_artist: debounce(function (media, e) {
                    let artist_name = e.target.value;

                    if (!media.primary_artist) {
                        media.primary_artist = {};
                    }

                    console.debug('primary_artist:', media.primary_artist);

                    // handle autocomplete
                    let ac_url = '/api/contentmanager/artist/';
                    $.ajax(ac_url, {
                        type: 'GET',
                        dataType: 'json',
                        contentType: 'application/json',
                        data: {q: artist_name},
                        async: false
                    }).done(function (data) {
                        console.log('ac:', data);
                        // $.each(data.results, function(i, el){
                        //    el.current = false;
                        // });
                        media.ac_primary_artist = data.results;
                        media.ac_primary_artist_current = -1;

                        self.vm.$forceUpdate();
                    });

                    media.primary_artist.name = artist_name;
                    media.primary_artist.uuid = '';

                }, 100),
                update_media_artist_uuid: debounce(function (media, e) {
                    let artist_uuid = e.target.value;

                    if (!media.primary_artist) {
                        media.primary_artist = {};
                    }

                    media.primary_artist.uuid = artist_uuid;
                }, 100),
                /******************************************************
                 TODO: autocomplete shizzle
                 ******************************************************/
                handle_ac_primary_artist: debounce(function (item, ac_result, key, e) {

                    // console.debug('handle_ac_primary_artist:', item, ac_result, key, e);

                    // handle up/down
                    if (key === 'down' && item.ac_primary_artist_current < item.ac_primary_artist.length) {
                        item.ac_primary_artist_current++;
                    }

                    if (key === 'up' && item.ac_primary_artist_current >= 0) {
                        item.ac_primary_artist_current--;
                    }

                    $.each(item.ac_primary_artist, function (i, el) {
                        el.current = item.ac_primary_artist_current === i;
                    });

                    // handle selection
                    if (key === 'enter' && item.ac_primary_artist_current >= 0 && item.ac_primary_artist_current < item.ac_primary_artist.length) {
                        let ac_result = item.ac_primary_artist[item.ac_primary_artist_current];
                        console.debug('select result:', ac_result);
                        item.primary_artist = ac_result;
                        item.ac_primary_artist = [];
                    }

                    if (key === 'click') {
                        console.debug('select result:', ac_result);
                        item.primary_artist = ac_result;
                        item.ac_primary_artist = [];
                    }

                    // handle exit (esc / tab)
                    if (key === 'exit') {
                        item.ac_primary_artist = [];
                    }

                    self.vm.$forceUpdate();



                }, 100),

                /******************************************************
                 TODO: quick-search implementation
                 ******************************************************/
                fs_browser_handle_quicksearch: debounce(function (e) {

                    let q = e.target.value.toLowerCase().replace('_', ' ').replace('-', ' ');
                    console.debug('quick search:', q);
                    //media.name = e.target.value;

                    $.each(this.fs_data.directories, function (i, el) {
                        let text = el.name.toLowerCase().replace('_', ' ').replace('-', ' ');
                        el.qs_hidden = text.indexOf(q) === -1;
                    });


                    self.vm.$forceUpdate();

                }, 100),


                remove_item: function (item, e) {
                    console.log('remove item:', item);

                    // if (!confirm('Do you really want to delete this track?')) {
                    //     return;
                    // }

                    self.vm.locked = true;

                    $.ajax(item.url, {
                        type: 'DELETE',
                        dataType: 'json',
                        contentType: 'application/json',
                        //async: false
                    }).done(function (data) {
                        console.log('done', data);
                        self.load_editor_data();

                        // if(self.vm.fs_data){
                        //     self.vm.load_fs_browser();
                        // }

                    }).fail(function (jqXHR, textStatus) {
                        console.log('fail', jqXHR, textStatus);
                        self.vm.locked = false;
                        alert(textStatus)
                    });


                }
            }
        });


        self.load_editor_data({initial: true});


        //self.setup_dropzone();
        // self.handle_sortable();


    };


    this.setup_dropzone = function () {

        if (self.debug) {
            console.debug('MediaEditorApp - setup_dropzone');
        }

        // no autodiscovery. setup dropzone 'manually'
        Dropzone.autoDiscover = false;

        let dz_url = self.api_url + 'dz-upload-file/';

        let dropzone_options = {
            // add csrf token
            headers: {
                'X-CSRFToken': $("input[name='csrfmiddlewaretoken']").val()
            },
            parallelUploads: 1,
            // image_uploader: 'temporary-image-upload'
            url: dz_url,
            clickable: '.media-editor-container .dz-upload-button',
            acceptedFiles: 'audio/*'
        };

        // initialize dropzone
        let d = new Dropzone("#dz_upload_container", dropzone_options);

        // add handlers
        d.on('success', function (file, data) {
            console.log('DATA', data);
            console.log('FILE', file);

            self.vm.media_set.push(data);


            // remove file from dropzone
            d.removeFile(file);

        })


    };



    this.load_editor_data = function (opts) {

        console.log('MediaEditorApp: load_editor_data', self.api_url);

        $.get(self.api_url, function (data) {
            console.log('MediaEditorApp - data:', data);
            self.vm.media_set = data.media_set;
            self.vm.locked = false;

            // check if empty release. if yes open preferred import option
            if(opts.initial === true) {
                if(self.vm.media_set.length < 1) {
                    self.vm.load_dz_uploader();
                }
            }

        });


    };


    this.save_editor_data = function () {

        console.log('MediaEditorApp: save_editor_content');

        self.vm.locked = true;

        $.each(self.vm.media_set, function (i, item) {
            console.log('MediaEditorApp: patching item:', item);
            $.ajax(item.url, {
                type: 'PATCH',
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify(item),
                async: false
            });
        });


        //self.load_editor_data();

    };


    this.click_bindings = function () {

        $('#media_editor').parents('form').on('submit', null, function (e) {
            //e.preventDefault();
            self.save_editor_data();
        });

    };


    return this.init(options);

};

module.exports = MediaEditorApp;

