<script>

    import {tween} from 'shifty';
    import Loader from '../../../components/loader.vue';
    import Visual from '../../../components/visual.vue';
    import {template_filters} from '../../../utils/template-filters';

    export default {
        props: [
            'item',
            'items_to_collect',
            'actions',
        ],
        data() {
            return {
                number: 10000,
                tweened_num_media: 0,
                tweened_duration: 0
            }
        },
        components: {
            Loader,
            Visual
        },
        computed: {
            in_playlist: function () {
                if (!this.items_to_collect || this.items_to_collect.length !== 1) {
                    return false
                }
                // TODO: fix/implement item_appearances
                // return false;
                const content = this.items_to_collect[0];
                return this.item.item_appearances.includes(`${content.ct}:${content.uuid}`);

            },
            animated_duration: function () {
                return (this.tweened_duration === 0) ? this.item.duration : this.tweened_duration;
            },
            animated_num_media: function () {
                return this.tweened_num_media;
            },
        },
        watch: {
            'item.duration': function (newValue, oldValue) {
                tween({
                    from: {n: oldValue},
                    to: {n: newValue},
                    duration: 500,
                    easing: 'easeOutQuad',
                    step: (state) => {
                        this.tweened_duration = state.n;
                        //this.tweened_duration = state.n.toFixed(0);
                    }
                })
            }
        },
        methods: {},
        filters: template_filters,
    }
</script>

<style lang="scss" scoped>

    $body-font-color: #333;

    // list styling
    .item {
        display: flex;
        position: relative;
        padding: 6px 6px;
        border-bottom: 1px solid #e8e8e8;

        &:first-child {
            border-top: 1px solid #e8e8e8;
        }
        &:hover {
            background: rgba(#1919ff, 0.2);
        }

        .visual {
            width: 52px;
            background: rgba(#000000, 0.07);
            figure {
                margin: 0;
            }
        }
        .information {
            flex-grow: 1;
            padding: 4px 10px 0;
            color: $body-font-color;
            .name {
                color: $body-font-color;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            .counts {
                opacity: .5;
            }
        }
        .actions {

            .button-group {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .button {
                    border: 1px solid #1919ff;
                    // text-transform: uppercase;
                    color: #1919ff;
                    padding: 2px 12px;
                    margin: 0 0 0 6px;
                    &:hover {
                        background: #1919ff;
                        border-color: #1919ff;
                        color: #FFFFFF;
                        //border-radius: 3px;
                        text-decoration: none;
                    }
                }
            }
        }

        // TODO: make loading container more generic
        .loading-container {
            background: #FFFFFF;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 99;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
        }
        .fade-enter-active {
            transition: all .05s;
        }
        .fade-leave-active {
            transition: all .1s;
        }
        .fade-enter, .fade-leave-to {
            opacity: 0;
        }
    }

</style>

<template>
    <div :key="item.uuid" class="item" v-bind:class="{ 'is-loading': item.loading, 'is-updated': item.updated }">
        <div class="visual">
            <visual v-bind:url="item.image"></visual>
        </div>
        <div class="information">
            <a class="name" href="#" @click.prevent="$emit('visit', item)">
                <i v-if="in_playlist" class="fa fa-star"></i>
                {{ item.name }}
            </a>
            <div v-if="item.series_display">
                <span>{{ item.series_display }}</span>
            </div>
            <div class="counts">
                <span>{{ animated_duration | s_to_time }}</span>
                &mdash;
                <span>{{ item.num_media }} tracks</span>
            </div>
        </div>
        <div class="actions">
            <div class="button-group">
                <a v-if="(actions.indexOf('add_and_close') > -1)" @click="$emit('add_and_close', item)"
                   class="button button--outline">
                    Add & Close
                </a>
                <a v-if="(actions.indexOf('add') > -1)" @click="$emit('add', item)"
                   class="button button--outline">
                    Add
                </a>
                <a v-if="(actions.indexOf('visit') > -1)" @click="$emit('visit', item)"
                   class="button button--outline">
                    Visit playlist
                </a>
            </div>
        </div>
        <transition name="fade">
            <div v-if="item.loading" class="loading-container">
                <span class="loading-info">
                    <loader></loader>
                </span>
            </div>
        </transition>
    </div>
</template>
