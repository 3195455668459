;
var NotificationApp = function (options) {

    var self = this;
    this.socket = null;
    this.app = null;

    this.init = function (options) {

        // exit here if no container available (=user not logged in)
        if(!$('#notifications').length) {
            return;
        }

        // initialize vue app
        self.vm = new Vue({
            el: '#notifications',
            data: {
                notifications: [],
                num_unseen: 0,
                latest_notification: null,
                is_active: false
            },
            computed: {
                container_state: function () {
                    return {
                        active: this.is_active
                    }
                }
            },

            methods: {
                toggle_active: function (event) {

                    event.stopPropagation();

                    this.is_active = !this.is_active;

                    if(this.is_active) {
                        this.latest_notification = null;

                        // set messages as unseen
                        var url = '/en/api/notification/notification/';
                        var data = {
                            action: 'all_seen'
                        };
                        $.ajax({
                            type: "POST",
                            url: url,
                            contentType: 'application/json',
                            data: JSON.stringify(data),
                            success: function (data) {
                                self.vm.num_unseen = data.num_unseen;
                            }
                        });
                    }

                },

                close_notification: function (event) {
                    self.vm.latest_notification = null;
                },

                latest_notification_on_enter: function (el, done) {
                    setTimeout(function(){
                        self.vm.close_notification();
                    }, 5000);
                    done();
                },

                hide_list: function () {
                    this.is_active = false;
                },
                show_list: function () {
                    this.is_active = true;
                    this.latest_notification = null;

                        // set messages as unseen
                        var url = '/en/api/notification/notification/';
                        var data = {
                            action: 'all_seen'
                        };
                        $.ajax({
                            type: "POST",
                            url: url,
                            contentType: 'application/json',
                            data: JSON.stringify(data),
                            success: function(data) {


                                self.vm.num_unseen = data.num_unseen;

                                //data.reverse();
                                //self.vm.notifications = data;
                            }
                        });

                }
            }

        });

        self.bindings();

        if(options.channels_enabled) {
            // TODO: channels - refactor to 2.0
            // var ws_scheme = window.location.protocol == "https:" ? "wss" : "ws";
            // self.socket = new ReconnectingWebSocket(ws_scheme + '://' + window.location.host + "/notification/stream/");
            // self.socket_bindings();
        } else {
            console.info('websockets disabled, you are probably using runserver...');
        }

        // load initial data
        var url = '/api/notification/notification/';
        $.ajax({
            type: "GET",
            url: url,
            contentType: 'application/json',
            success: function(data) {
                self.vm.notifications = data.results;
                self.vm.num_unseen = data.num_unseen;
            }
        });

    };


    this.bindings = function() {
        $(document).on('click', function(){
            self.vm.hide_list();
        });

        $(document).on('show.zf.dropdownmenu', function() {
            self.vm.hide_list();
        });

    };


    this.socket_bindings = function () {

        self.socket.onmessage = function (message) {


            var data = JSON.parse(message.data);

            /*
             * TODO: extremely ugly hack here!
             * we check for a running chat instance here and just
             * short-circuit message delivery... shame on me.
             */

            var chat_container = $('#chat_app[data-uuid="' + data.receiver_uuid + '"]');

            if(chat_container.length){
                $(document).trigger('chat-notification', [ data.receiver_uuid, data ]);
            } else {

                self.vm.notifications.unshift(data);
                self.vm.num_unseen++;
                self.vm.latest_notification = data;

            }

            // confirm back delivery
            data = {
                uuid: data.uuid,
                mark_as: 'delivered'
            };
            self.socket.send(JSON.stringify(data));

        };

        // debugging sockets
        self.socket.onopen = function () {
            console.log("Connected to chat socket");
        };
        self.socket.onclose = function () {
            console.log("Disconnected from chat socket");
        }

    };

    return this.init(options);

};

module.exports = NotificationApp;
