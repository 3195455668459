const DEBUG = false;

const CONTAINER_CLASS = 'flyout-navigation';

class NavigationApp {

    constructor(opts) {
        if (DEBUG) console.log('NavigationApp: constructor');

        // this.container = document.querySelector(`.${CONTAINER_CLASS}`);
        this.visible = false;

        this.bindings();
    };

    bindings() {
        $(document).on('click', '[data-flyout-toggle]', (e) => {
            e.preventDefault();
            this.toggle();
        });
        $(document).on('click', '.flyout-navigation a', () => {
            this.hide();
        });
    };

    toggle() {
        this.container = document.querySelector(`.${CONTAINER_CLASS}`);
        (this.visible) ? this.hide() : this.show();
    };

    show() {
        this.container.classList.add(`${CONTAINER_CLASS}--expanded`);
        this.visible = true;
    };

    hide() {
        this.container.classList.remove(`${CONTAINER_CLASS}--expanded`);
        this.visible = false;

    };

}

module.exports.NavigationApp = NavigationApp;
