;

class HTMLExtraApp {

    constructor(opts) {
        this.bindings();
    };

    bindings() {

        $(document).on('click', '[data-html-extra-expandable-block] [data-toggle-block ]', (e) => {
            e.preventDefault();
            let container = $(e.target).parents('[data-html-extra-expandable-block]');
            container.toggleClass('expanded');
        });

    };

    toggle_flyout() {

    };

}

module.exports.HTMLExtraApp = HTMLExtraApp;
