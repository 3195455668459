;

var ChatApp = function (options) {

    var self = this;
    this.debug = false;
    this.vm = null;
    this.user = null;
    this.api_next_url = false;
    this.api_count = 0;

    this.init = function (options) {

        self.user = options.user;
        self.bindings();
        self.prepare();
    };

    /*
     * bindings are global on document. so we only have to call them once
     */
    this.bindings = function () {

        if (self.debug) {
            console.log('ChatApp - bindings');
        }

        // action bindings
        $(document).on('click', '.actions a[data-action]', function (e, b, c) {

            var el = $(this);
            var container = el.parents('.container');
            var uuid = container.data('uuid');


        });

        $(document).on('chat-notification', function(e, uuid, message) {

            if (self.vm.messages.length > 10) {
                self.vm.messages.pop();
            }

            if (self.debug) {
                console.log('ChatApp - got notification', message);
            }

            // we load the respective message from the chat API
            $.ajax({
                type: "GET",
                url: message.url,
                contentType: 'application/json',
                success: function(message) {
                    self.vm.messages.unshift(message);
                }
            });

        });

        // TODO: think about an elegant way to handle this...
        // we need to re-initialize the chat in case it is in the dom
        $(document).on('content:changed', function(e) {
            self.prepare();
        });

    };

    this.prepare = function () {

        var container = $('#chat_container');

        if(!container.length) {
            return;
        }

        var resource_uri = container.find('#chat_app').data('resource-uri');

        self.vm = new Vue({
            el: '#chat_container',
            data: {
                messages: [],
                input: '',
                showFS: false,
                resource_uri: resource_uri
            },
            methods: {
                update: function (e) {
                    this.input = e.target.value
                },
                submit: function (e) {

                    if(this.input.length < 2) {
                        return;
                    }

                    var data = {
                        message: this.input
                    };

                    var vm = this;

                    $.ajax({
                        type: "POST",
                        url: this.resource_uri,
                        contentType: 'application/json',
                        data: JSON.stringify(data),
                        success: function(data) {
                            console.log('posted chat message:', data);
                            vm.input = '';
                            self.append_messages(data);
                        }
                    });
                },
                load_history: function (e) {

                    if(!self.api_next_url) {
                        return;
                    }

                    var vm = this;

                    $.ajax({
                        type: "GET",
                        url: self.api_next_url,
                        contentType: 'application/json',
                        success: function(data) {
                            self.prepend_messages(data.results);
                            self.api_next_url = data.next;
                            self.api_count = data.count;
                        }
                    });
                }
            }
        });


        // load initial chat messages
        $.ajax({
            type: "GET",
            url: resource_uri,
            data: 'limit=8',
            contentType: 'application/json',
            success: function(data) {
                self.prepend_messages(data.results);
                self.api_next_url = data.next;
                self.api_count = data.count;
            }
        });

    };

    /******************************************************************
     * ATTENTION: append/prepend are kind of inverse (compared to
     * jQuery logic...)
     *
     * as chat direction is top-down, `append_messages` means adding *new*
     * messages - they will appear on top (and not bottom).
     * and reversed behavior respective for `prepend_messages`
     *****************************************************************/
    this.append_messages = function(messages) {

        $.each(messages, function(i, item){
            self.vm.messages.unshift(item);
        });
    };

    this.prepend_messages = function(messages) {

        $.each(messages, function(i, item){
            self.vm.messages.push(item);
        });
    };



    return this.init(options);

};

module.exports = ChatApp;
