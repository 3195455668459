<script>
    export default {
        name: 'SkipIcon',
        props: {
            // color: String,
            color_hover: String,
            color: {
              type: String,
              default: '#000'
            },
            hoverColor: {
              type: String,
              default: '#1919FF'
            },
            size: {
              type: Number,
              default: 24
            },
            disabled: {
              type: Boolean,
              default: false
            },
        },
        data() {
            return {
                hover: false,
            }
        },
        computed: {
            _color() {
                return this.color;
            },
            _hoverColor() {
                return (this.hoverColor && !this.disabled) ? this.hoverColor : this._color;
            },
            iconStyle() {
                return {
                    width: `${this.size}px`,
                    height: `${this.size}px`,
                    cursor: (this.disabled) ? 'not-allowed' : 'pointer',
                }
            },
            svgStyle() {
                return {
                    fill: (this.hover) ? this._hoverColor : this._color,
                }
            },
        },
        methods: {
            click: function (e) {
                this.$emit('click');
            },
        },
    }
</script>
<style lang="scss" scoped>
    .icon {
        > svg {
            width: 100%;
            height: 100%;
            transition: fill 200ms;
        }
    }
</style>
<template>
    <div
        class="icon"
        :style="iconStyle"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        @click="click($event)">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            :style="svgStyle"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
        </svg>
    </div>
</template>
