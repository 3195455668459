;

import axios from 'axios';


const client = axios.create({
    xsrfHeaderName: 'X-CSRFTOKEN',
    xsrfCookieName: 'csrftoken',
});

const DEBUG = false;


/**********************************************************************
 * simple context menu handling app
 *********************************************************************/
class XHRForm {
    /**
     *
     * @param opts
     */
    constructor(opts) {

        if (DEBUG) console.log('XHRForms: constructor', opts);

        $(document).on('submit', 'form:not([data-xhr-exclude])', (e) => {

            e.preventDefault();
            this.process_form(e.currentTarget);
        });

    };

    /**
     *
     */
    process_form(form) {

        if (DEBUG) console.log('XHRForms: process form', form);


        let el = $(form);
        let url = el.attr('action') || document.location.href;
        //console.log(el.serialize());

        let config = {
            headers: {
                'content-type': 'application/x-www-form-urlencode'
            }
        };

        // let data = el.serialize();
        //
        // console.log('el', el.serializeArray());
        // console.log('data', data);

        let form_data = new FormData();

        for (let x of el.serializeArray()) {
            form_data.set(x.name, x.value);
        }

        client.post(url, form_data, config)
            .then((response) => {
                //console.debug(response)
                //console.debug(response.request.responseURL)

                Turbolinks.clearCache();

                if (response.request.responseURL && (response.request.responseURL !== document.location.href)) {
                    Turbolinks.visit(response.request.responseURL);
                } else {
                    Turbolinks.controller.render({
                        snapshot: response.data,
                        error: false,
                        isPreview: false
                    }, function () {
                    })
                }


                //
                // let r = response.request.responseURL || document.location.href;
                //
                // Turbolinks.clearCache();
                // Turbolinks.visit(r);
            }, (error) => {
                console.warn(error)
            })


    };


}

module.exports = XHRForm;
