;
var SiteUI = function(options) {

    var self = this;
    this.debug = false;
    self.has_inline_nav = true;

    this.init = function (options) {
        self.bindings();
        self.on_ready();
    };

    this.bindings = function () {

        // pass event to on_ready
        $(document).on('content:changed', function (e) {
            self.on_ready(e);
        });

    };

    /******************************************************************
     * wrapper to handle plugins that rely on:
     *     $( document ).ready(function() {
     *         ....
     *****************************************************************/
    this.on_ready = function(e) {


        // $(document).foundation();
        // $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize){});

        // wysiwyg editor
        $('textarea.wysiwyg').trumbowyg({
            svgPath: '/static/icons/trumbowyg.svg',
            resetCss: true,
            autogrow: true,
            removeformatPasted: false,
            semantic: true,
            btns: [
                ['p', 'blockquote'],
                ['strong', 'em'],
                ['createLink', 'unlink'],
                ['unorderedList', 'orderedList'],
                ['removeformat'],
                ['viewHTML', 'fullscreen']
            ]
        });

        /*
        $('.datetimepicker').datetimepicker({
            format: 'Y-m-d H:i',
            mask: true
        });
        */


    };

    return this.init(options);

};

$(function () {
    var ui = new SiteUI();
});
