import store from "../../store";
import Vue from 'vue';
import APIClient from '../../api/client';
import Loader from '../../components/loader.vue'
import debounce from 'debounce';
import ClickOutside from 'vue-click-outside';

const DEBUG = false;

const SearchApp = Vue.extend({
    // props: [
    //     'search_scope'
    // ],
    components: {
        Loader
    },
    data() {
        return {
            loading: false,
            active: false,
            search_input_has_focus: false,
            search_query_string: '',
            search_query: null,
            search_total_results: 0,
            search_results: [],
            selected_search_result: -1,
            search_display_box: {
                left: 0,
                width: 100
            },

            search_scope: '_all',
            search_scopes: [
                {
                    ct: '_all',
                    name: 'All',
                    shortcut: '_',
                },
                {
                    ct: 'catalog.artist',
                    name: 'Artist',
                    shortcut: 'a',
                    list_url: '/artists/'
                },
                {
                    ct: 'catalog.release',
                    name: 'Release',
                    shortcut: 'r',
                    list_url: '/releases/'
                },
                {
                    ct: 'catalog.label',
                    name: 'Label',
                    shortcut: 'l',
                    list_url: '/labels/'
                },
            ]
        }
    },
    created() {
        let scope = $('body').data('scope');

        if (scope !== undefined && scope !== '') {
            console.log('scope:', scope);
            this.search_scope = scope;
        }

    },
    mounted() {

        const container = $('#search_app_container');

        // clobally bind shift+space
        $(document).on('keyup', (e) => {
            if (e.which === 32 && e.shiftKey) {
                $('.search-input input', container).focus();
            }
        });
    },
    directives: {
        ClickOutside
    },
    computed: {
        settings: () => store.state.settings,
        result_is_visible: function () {
            return this.search_results.length > 0;
        },
        search_input_cursor_offset: function () {
            return this.search_query_string.length * 8;
        },
        search_input_cursor_blink: function () {
            return (this.search_input_has_focus || this.settings.search_hints_enabled);
        },
        search_scope_display: function () {
            let scope = this.search_scopes.find((scope) => {
                return scope.ct === this.search_scope
            });
            return scope.name;
        },
        search_scope_display_width: function () {
            let scope = this.search_scopes.find((scope) => {
                return scope.ct === this.search_scope
            });
            return scope.name.length * 8 + 20;
        }
    },
    methods: {

        // store methods
        //update_settings: (key, value) => store.commit('update_settings', {key: key, value: value}),
        update_settings: function (key, value) {
            store.commit('update_settings', {key: key, value: value});
            // query needs to be refreshed if mode changes
            if (key === 'search_fuzzy_match_mode') {
                this.load_search_results();
            }

        },
        ///////////////////////////////////////////////////////////////
        // component activation / deactivation
        ///////////////////////////////////////////////////////////////
        activate_search: function (e) {

            // TODO: improve size/position handling
            // this.search_display_box.left = $('.controls.search-input').position().left;
            // this.search_display_box.width = $('.controls.search-input').width();

            this.active = true;
        },
        deactivate_search: function (e) {
            this.search_results = [];
            this.search_query_string = '';
            this.active = false;
        },

        ///////////////////////////////////////////////////////////////
        // input field handling
        ///////////////////////////////////////////////////////////////
        update_query_string: function (e) {
            let q = e.target.value;
            console.debug(q);

            this.search_query_string = q;

            if (q.length > 1) {
                //this.search_results = dummy_results;
                this.parse_query_string(q);
                this.load_search_results({q: q});
            } else {
                this.search_results = [];
            }

        },
        search_input_focus: function () {
            store.commit('update_settings', {
                key: 'search_hints_enabled',
                value: false
            });
            this.activate_search();
            this.search_input_has_focus = true;
        },
        search_input_blur: function () {
            this.search_input_has_focus = false;
        },
        search_input_esc: debounce(function (e) {
            //this.deactivate_search();
            this.search_query_string = '';
            this.search_results = [];

            $('#search_app_container .search-input input').blur();

        }, 100),

        ///////////////////////////////////////////////////////////////
        // result selection & navigation
        ///////////////////////////////////////////////////////////////
        select_search_result: function (e, offset) {
            e.stopPropagation();
            if (offset) {
                this.selected_search_result += offset;

                if (this.selected_search_result < -1) {
                    this.selected_search_result = -1;
                }
                if (this.selected_search_result >= this.search_results.length) {
                    this.selected_search_result = this.search_results.length - 1;
                }
            } else {
                this.selected_search_result = -1;
            }

            $.each(this.search_results, (i, el) => {
                el.selected = i === this.selected_search_result;
            });

        },
        // result navigation
        navigate_to_selection: function (e) {

            let item = null;

            if (this.search_results.length === 1) {
                item = this.search_results[0];
            } else {
                item = this.search_results[this.selected_search_result];
            }


            if (item !== undefined) {
                this.search_results = [];
                this.search_query_string = '';
                //document.location.href = item.url;
                Turbolinks.visit(item.url);
            } else {

                //alert(this.search_query_string);
                let scope = this.search_scopes.find((scope) => {
                    return scope.ct === this.search_scope
                });

                if (scope.list_url === undefined) {
                    return;
                }

                let q = this.search_query_string;
                if (q[1] === ':') {
                    q = $.trim(q.substr(2));
                }

                let params = {
                    search_q: q,
                    option_fuzzy: (this.settings.search_fuzzy_match_mode ? 1 : 0)
                };

                Turbolinks.visit(scope.list_url + '?' + $.param(params));
                //document.location.href = scope.list_url + '?' + $.param(params);

            }

        },
        // result navigation
        navigate_to_item: function (e, item) {
            document.location.href = item.url;
        },

        ///////////////////////////////////////////////////////////////
        // parse query string
        ///////////////////////////////////////////////////////////////
        parse_query_string: function (q) {

            if (q && q.length >= 2 && q[1] === ':') {
                let shortcut = q[0];

                let scope = this.search_scopes.find((scope) => {
                    return scope.shortcut === shortcut
                });
                this.set_search_scope(scope.ct);

                console.log('shortcut detected:', shortcut, scope);


            }
        },

        ///////////////////////////////////////////////////////////////
        // settings / scope
        ///////////////////////////////////////////////////////////////
        set_search_scope: function (scope) {
            if (scope) {
                this.search_scope = scope;
            } else {
                this.search_scope = null;
            }
            this.load_search_results();
        },

        ///////////////////////////////////////////////////////////////
        // data handling / ajax loading
        ///////////////////////////////////////////////////////////////
        load_search_results: debounce(function (query = false) {

            if (!query) {
                query = {
                    q: this.search_query_string
                }
            }
            if (query.q.length < 1) {
                this.search_results = [];
                this.search_total_results = 0;
                return
            }

            // add search options
            query['fuzzy'] = (this.settings.search_fuzzy_match_mode ? 1 : 0);
            query['ct'] = this.search_scope;


            console.debug('query', query);
            this.loading = true;

            let url = '/api/search/global/';

            APIClient.get(url, {params: query})
                .then((response) => {
                    this.loading = false;

                    let max_score = response.data.max_score;

                    // TODO: make this less ugly...
                    $.each(response.data.results, (i, el) => {
                        el.selected = false;
                        //el.score = el.score / max_score;
                        el.top_hit = el.score > 8;
                        //el.top_hit = (el.score / max_score) > 0.7;
                        el.scope = this.search_scopes.find((scope) => {
                            return scope.ct === el.ct
                        });
                    });

                    this.search_total_results = response.data.total;
                    this.search_results = response.data.results;

                }, (error) => {
                    this.loading = false;
                })

        }, 350),

    }
});

module.exports = SearchApp;
