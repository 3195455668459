<script>
    export default {
        name: 'PlayIcon',
        props: {
            color: String,
            color_hover: String,
            item: Object,
        },
        data() {
            return {
                hover: false,
            }
        },
        computed: {
            _color() {
                if (this.item.is_buffering) {
                    return '#eee';
                }
                return (this.color) ? this.color : '#fff';
            },
            _color_hover() {
                return (this.color_hover) ? this.color_hover : this._color;
            },
            state() {
                if (!this.item) {
                    return null;
                }
                if (this.item.is_buffering) {
                    return 'buffering';
                }
                if (this.item.is_playing) {
                    return 'playing';
                }
                if (this.item.is_current) {
                    return 'paused';
                }
                return 'stopped';
            }
        },
        methods: {
            click: function (e) {
                console.debug('click', this.state);
                let action = 'play';

                if (this.state === 'playing') {
                    action = 'pause';
                }

                if (this.state === 'paused') {
                    action = 'resume';
                }

                this.$emit('click', action, this.item);
            },
        },
    }
</script>
<style lang="scss" scoped>


    .play-icon {

        position: relative;
        width: 24px;
        height: 24px;
        border-radius: 50%;

        .bars {

            transform: scale(.6);

        }

        cursor: pointer;

        &.playing {

            background: rgba(#000, 1);

            .bars > div {
                animation: a-playing 1.4s infinite;
            }

            .bars .rect2 {
                animation-delay: -.7s;
            }

            .bars .rect1 {
                animation-delay: .1s;
            }

            .bars .rect5 {
                animation-delay: -.2s;
            }

            .bars .rect2 {
                animation-delay: -.4s;
            }

            .bars .rect3 {
                animation-delay: -0.9s;
            }

            .bars .rect4 {
                animation-delay: -0.6s;
            }

        }

        &.buffering {

            background: #999;

            .bars > div {
                animation: a-buffering 1.4s infinite;
            }

            .bars .rect1 {
                animation-delay: 0s;
            }

            .bars .rect2 {
                animation-delay: .3s;
            }

            .bars .rect3 {
                animation-delay: .6s;
            }

            .bars .rect4 {
                animation-delay: .9s;
            }

            .bars .rect5 {
                animation-delay: 1.2s;
            }
        }
    }

    .bars {
        position: absolute;
        left: 5px;
        top: 5px;
        width: 14px;
        height: 14px;
        text-align: center;
        font-size: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .bars > div {
        background-color: #f0f;
        height: 100%;
        width: 2px;
        display: inline-block;
    }

    @keyframes a-playing {
        0%, 80%, 100% {
            //transform: scaleY(0.4);
            height: 20%;
        }
        20% {
            //transform: scaleY(1.0);
            height: 90%;
        }
        40% {
            //transform: scaleY(1.0);
            height: 70%;
        }
        60% {
            //transform: scaleY(1.0);
            height: 80%;
        }
    }

    @keyframes a-buffering {
        0%, 100% {
            //transform: scaleY(0.4);
            height: 0%;
        }
        99% {
            //transform: scaleY(1.0);
            height: 100%;
        }
    }

</style>
<template>
    <div
        class="play-icon"
        @click="click($event)"
        v-bind:class="state">
        <div
            v-if="( state === 'playing' || state === 'buffering' )"
            class="bars">
            <div class="rect1" v-bind:style="{ background: _color }"></div>
            <div class="rect2" v-bind:style="{ background: _color }"></div>
            <div class="rect3" v-bind:style="{ background: _color }"></div>
            <div class="rect4" v-bind:style="{ background: _color }"></div>
            <div class="rect5" v-bind:style="{ background: _color }"></div>
        </div>
        <div
            v-if="( state === 'paused' || state === 'stopped' )"
            class="i">
            <i class="mi">play_arrow</i>
        </div>
    </div>
</template>
