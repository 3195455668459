// atPoint:[0,1]
function linearInterpolate(before, after, atPoint) {
    return before + (after - before) * atPoint;
}

function _interpolate_array(data, fitCount) {
    var newData = [];
    var springFactor = Number((data.length - 1) / (fitCount - 1));
    newData[0] = data[0]; // for new allocation
    for (var i = 1; i < fitCount - 1; i++) {
        var tmp = i * springFactor;
        var before = Number(Math.floor(tmp)).toFixed();
        var after = Number(Math.ceil(tmp)).toFixed();
        var atPoint = tmp - before;
        newData[i] = linearInterpolate(data[before], data[after], atPoint);
    }
    newData[fitCount - 1] = data[data.length - 1]; // for new allocation
    return newData;
}

export function interpolate_array(data, new_length) {
    return _interpolate_array(data, new_length)
}
