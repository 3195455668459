;

class PublisherApp {

    constructor(opts) {

        this.container = null;

        this.bindings();
        $(document).on('content:changed', () => {
            this.bindings();
        })
    };

    bindings() {

        this.container = $('form#publisher');

        if(this.container.length < 1) {
            return;
        }

        $('[data-action]', this.container).on('click', (e) => {
            e.preventDefault();

            let action = $(e.target).data('action');

            if(action === 'publish') {
                this.publish();
            }

        })

    };

    publish() {

        let input = $('#id_published', this.container);
        let d = new Date();
        let d_str = d.toISOString().replace('T', ' ').slice(0, 16);


        input.val(d_str);

        $('#submit-id-submit', this.container).trigger('click');

        //this.container.trigger('submit');


    };

}

module.exports = PublisherApp;
