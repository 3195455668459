;

import Vue from 'vue';
import isUrl from 'is-url';


let URL_INVALID_ERROR = 'Invalid URL. A valid URL should start with //, http:// or https://';
const DEBUG = false;

class RelationEditorApp {

    constructor(opts) {

        if (DEBUG) console.log('RelationEditorApp: constructor');

        this.vm = null;
        this.relations = [];

        this.setup();

        $(document).on('content:changed ready', (e) => {
            this.setup();
        });

    };

    bindings() {

        let container = $('#relation_editor');


        container.parents('form').on('submit', (e) => {

            // check if 'add url input' has focus
            let any_input_has_focus = false;
            $('#relation_editor input').each((i, el) => {
                if($(el).is(':focus')) {
                    any_input_has_focus = true;
                }
            });

            if(any_input_has_focus) {
                if (DEBUG) console.log('has focus > skip');
                e.preventDefault();
                //return;
            } else {
                if (DEBUG) console.log('no focus > submit');
                $('#id__relation_data').val(JSON.stringify(this.vm.relations));
            }
        });

    };

    setup() {

        if (DEBUG) console.log('RelationEditorApp: setup');

        let container = $('#relation_editor');

        if (container.length < 1) {
            if (DEBUG) console.log('RelationEditorApp - no container for relation editor app available');
            return;
        }

        this.bindings();

        // TODO: make generic
        $('form.disable-validation').attr('novalidate', 'novalidate');

        //initialize vue app
        this.vm = new Vue({
            el: '#relation_editor',
            data: {
                relations: [],
                new_relation: {
                    url: '',
                    error: false
                }
            },
            methods: {
                add_item: (function (item, e) {


                    if (! isUrl(item.url)) {
                        item.error = URL_INVALID_ERROR;
                        return;
                    }

                    // TODO: not so nice...
                    item.delete = false;
                    item.error = false;

                    this.relations.push(Object.assign({}, item));

                    this.new_relation.url = '';
                    this.new_relation.error = false;
                }),
                update_item: (function (item, e) {
                    item.url = e.target.value;

                    if (! isUrl(item.url)) {
                        item.error = URL_INVALID_ERROR;
                    } else {
                        item.error = false;
                    }

                }),
                remove_item: (function (item, e) {
                    e.preventDefault();
                    item.delete = true;
                })
            },

        });


        // load initial relations
        $('[data-current-relations] [data-uuid]', container).each((i, el) => {
            let item = $(el).data();

            // TODO: not so nice...
            item.delete = false;
            item.error = false;

            this.vm.relations.push(item);
        })


    };


}



//module.exports = RelationEditorApp;
module.exports.RelationEditorApp = RelationEditorApp;



