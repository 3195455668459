// import lightbox from 'simplelightbox';
import lightbox from 'simplelightbox/dist/simple-lightbox.js';
const DEBUG = true;

console.debug("lightbox", lightbox)

export default class Lightbox {
    constructor() {
        if (DEBUG) console.debug('LightboxApp - init');
        this.lb = null;
        this.lb_options = {
            sourceAttr: 'data-src',
            animationSlide: false,
            loop: false,
            history: false,
            preloading: false,
            widthRatio: .7,
            heightRatio: .7,
            animationSpeed: 100,
        };
        this.build_lightbox();
        $(document).on('content:changed', (e) => {
            this.build_lightbox();
        });
    }

    build_lightbox() {
        if (DEBUG) console.debug('LightboxApp - build', this.lb);
        const sel = $('.visual--lightbox figure');
        if(sel.length < 1) {
            return;
        }
        // sel.simpleLightbox(this.lb_options);
    }
}
