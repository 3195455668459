<script>

    const DEBUG = false;

    import Visual from '../../../components/visual.vue';
    import ObjectRating from '../../../components/rating/ObjectRating.vue';
    import PlayIcon from './play-icon.vue'

    export default {
        name: 'PlayerDetailQueueItem',
        components: {
            'visual': Visual,
            'rating': ObjectRating,
            'play-icon': PlayIcon,
        },
        directives: {},
        props: {
            item: {
              type: Object,
              default: null
            },
        },
        data() {
            return {
                isFullscreen: false,
            }
        },
        mounted: function () {

        },
        computed: {
            settings() {
                // return this.$store.getters['scheduler/settings'];
            },

        },
        methods: {

            setSchedulerSizes: function() {
                const calendarContainer = this.$refs['calendar'];
                const rect = calendarContainer.$el.getBoundingClientRect();
                const calendarWidth = rect.width;
                this.calendarWidth = calendarWidth;
            },

            controls: function(action, item) {
                console.debug(action, item);
                this.$emit('controls', action, item);
            },
            hide: function () {
                this.$emit('hide');
            },

        },
    }
</script>
<style lang="scss" scoped>
    .item {
        // background: #00FFFF;
        border-top: 1px solid rgb(238, 238, 238);
        padding: 6px 0;
        display: flex;
        align-items: center;
        height: 48px;

        &:hover {
            background: #fafafa;
        }

        &__visual {
            margin: 0 1rem;
        }

        &__metadata {
            flex-grow: 1;

            color: #333;
            line-height: 150%;

            a {
                color: inherit;
                &:hover {
                    text-decoration: underline;
                }
            }

            &__secondary {
                font-size: 90%;
            }
        }

        &__rating {
            padding-right: 1rem;
        }

    }
</style>

<template>
    <div class="item">

        <div class="item__actions">
            <play-icon
                :item="item"
                @click="controls(...arguments)"></play-icon>
        </div>

        <div class="item__visual">
            <visual
                :max-size="30"
                :url="item.media.image"></visual>

        </div>

        <div class="item__metadata">
            <div class="item__metadata__primary">
                {{ item.media.name }}
            </div>
            <div class="item__metadata__secondary">
                    <a
                        class="secondary"
                        @click="hide"
                        :href="item.media.artist_detail_url">{{ item.media.artist_display }}</a>
            </div>
        </div>

        <div class="item__rating">
            <rating
                :size="16"
                :obj="item.media"></rating>
        </div>

    </div>
</template>
