import APIClient from "../api/client";

const DEBUG = false;

export const visit_by_resource = function (item, scope) {
    if (DEBUG) console.debug('visit:', item, scope);
    const url = (scope === undefined) ? item.url : item[scope];
    APIClient.get(url)
        .then((response) => {
            const detail_url = response.data.detail_url;
            if (DEBUG) console.debug('visit:', detail_url);
            Turbolinks.visit(detail_url);
        }, (error) => {
            console.error('error loading item', error);
        });
};
