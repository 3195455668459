<script>

    const DEBUG = false;

    import Visual from '../../../components/visual.vue';
    import ObjectRating from '../../../components/rating/ObjectRating.vue';
    import PlayerDetailQueueItem from './PlayerDetailQueueItem.vue';

    export default {
        name: 'PlayerDetailQueue',
        components: {
            'visual': Visual,
            'rating': ObjectRating,
            'queue-item': PlayerDetailQueueItem,
        },
        directives: {},
        props: {
            itemsToPlay: {
              type: Array,
              default: () => []
            },
        },
        data() {
            return {
                isFullscreen: false,
            }
        },
        mounted: function () {

        },
        computed: {
            settings() {
                // return this.$store.getters['scheduler/settings'];
            },

        },
        methods: {

            setSchedulerSizes: function() {
                const calendarContainer = this.$refs['calendar'];
                const rect = calendarContainer.$el.getBoundingClientRect();
                const calendarWidth = rect.width;
                this.calendarWidth = calendarWidth;
            },

            controls: function(action, item) {
                console.debug(action, item);
                this.$emit('controls', action, item);
            },
            hide: function () {
                this.$emit('hide');
            },

        },
        watch: {
            // settings: function (o, n) {
            //     this.debouncedUpdateSchedule();
            // },
            // isFullscreen: function (o, n) {
            //     this.$nextTick(() => {
            //        this.setSchedulerSizes();
            //     });
            //
            // },
        },
    }
</script>
<style lang="scss" scoped>
    .queue {
        // background: #00FFFF;
    }
</style>

<template>
    <div class="queue">
        <div
            v-for="itemToPlay in itemsToPlay">
            <!--
            {{ itemToPlay.name }} * {{ itemToPlay.ct }}
            -->
            <queue-item
                v-for="(item, index) in itemToPlay.items"
                @controls="controls(...arguments)"
                @hide="hide"
                :key="item.media.uuid + index"
                :item="item"></queue-item>
        </div>
    </div>
</template>
