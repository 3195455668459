<script>
    import Visual from '../../../components/visual.vue';
    import {template_filters} from '../../../utils/template-filters';

    const DEBUG = false;

    export default {
        props: [
            'item'
        ],
        components: {
            Visual
        },
        data() {
            return {}
        },
        mounted: function () {
            if (DEBUG) console.debug('Release - mounted');
        },
        computed: {},
        methods: {
            // TODO: just testing...
            collect: function (item, e) {
                const _e = new CustomEvent('collector:collect', {detail: [item]});
                window.dispatchEvent(_e);
            }
        },
        filters: template_filters,
    }

</script>

<style lang="scss" scoped>

    $body-font-color: #000;

    .item {
        display: flex;
        .visual {
            figure {
                width: 42px;
                height: 42px;
            }
        }
        .information {
            flex-grow: 1;
            padding: 4px 0 0 10px;
            line-height: 16px;

            a {
                color: $body-font-color;
                &:hover {
                    color: #1919ff;
                }
            }
            &:hover {
                a {
                    text-decoration: underline;
                }
            }

            .title,
            .aux {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .aux {
                opacity: .7;
            }

        }
        .actions {
            position: relative;
            .controls-right {
                position: relative;
            }
            .button-panel {
                position: relative;
            }
        }
    }

</style>

<template>
    <div :key="item.key" class="item" @mouseover="is_hover=true" @mouseleave="is_hover=false">
        <div class="visual">
            <visual v-bind:url="item.media.image"></visual>
        </div>
        <div class="information">
            <div class="title">
                <a :href="item.media.detail_url">{{ item.media.name }}</a>
                <span v-if="item.is_current">(C)</span>
                <span v-if="item.is_playing">(P)</span>
            </div>
            <div class="aux">
                <span>by</span>
                <a :href="item.media.artist_detail_url">{{ item.media.artist_display }}</a>
                &mdash;
                <a :href="item.media.release_url">{{ item.media.release_display }}</a>
            </div>
        </div>
        <div class="actions">
            <div class="controls controls-right">
                <div class="button-panel">
                    <div data-ctx-menu class="context-menu">
                        <i data-ctx-menu-toggle class="mi">more_horiz</i>
                        <div data-ctx-menu-panel class="context-menu-panel">
                            <ul class="context-menu-actions">
                                <li @click.prevent="collect(item.media)" class="action">
                                    <span class="mic">
                                        <i class="mi">playlist_add</i>
                                    </span>
                                    <span>Add to Playlist</span>
                                </li>
                                <li class="action">
                                    <span class="mic">
                                        <i class="mi">file_download</i>
                                    </span>
                                    <a data-turbolinks="false" href="playing_now.detail_url">
                                        Download
                                    </a>
                                </li>
                                <li class="separator"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
