const DEBUG = false;

var QuickSearchApp = function (options) {

    var self = this;
    this.debug = true;

    this.init = function (options) {

        self.bindings();

        $(document).on('content:changed', function (e) {
            self.bindings();
        });


    };

    this.bindings = function () {
        var search_container = $('[data-quick-search]');
        if(search_container.length < 1) {
            return;
        }
        search_container.on('keyup', '[data-quick-search-input]', function (e) {
            e.preventDefault();
            var q = $(this).val().toLowerCase();
            if (self.debug) {
                console.log('QuickSearchApp - input:', q);
            }
            self.update_search(q, search_container);
        });
    };

    this.update_search = function(q, search_container) {

        $('[data-quick-search-text]', search_container).each(function(i, el){
            var item = $(this);
            var text = item.data('quick-search-text').toLowerCase();

            //if($.inArray( q, text ) > -1){
            if(text.indexOf(q) !== -1){
                item.show();
            } else {
                item.hide();
            }
        });
    };

    return this.init(options);

};

module.exports = QuickSearchApp;
