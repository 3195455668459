import { render, staticRenderFns } from "./image-editor.vue?vue&type=template&id=0161af50&scoped=true"
import script from "./image-editor.js?vue&type=script&lang=js&external"
export * from "./image-editor.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0161af50",
  null
  
)

export default component.exports