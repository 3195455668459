import APIClient from "../api/client";
import debounce from 'debounce';
import draggable from 'vuedraggable'

//import Vue from 'vue';
//import Sortable from 'vue-sortable';
// Vue.directive('sortable', {
//   inserted: function (el, binding) {
//     new Sortable(el, binding.value || {})
//   }
// })

const DEBUG = false;
const AUTOCOMMIT = true;


const draw_waveform = (canvas, waveform, position) => {
    if (DEBUG) console.debug('draw waveform', canvas, waveform, position);
};


export default {
    name: 'PlaylistEditor',
    components: {
        draggable,
    },
    props: [
        'myvar'
    ],
    data() {
        return {
            api_url: '',
            items: [],
            is_processing: false,
            myvarx: this.myvar
        }
    },
    mounted() {
        if (DEBUG) console.debug('PlaylistEditor - mounted');

        const config = JSON.parse(
            document.getElementById('playlist_editor_config').innerHTML
        );

        this.api_url = config.api_url;

        this.load_playlist();

    },
    computed: {
        // playlists: () => store.state.collector.playlists
    },
    methods: {
        drag_end: function (e) {
            //console.log(e);
            this.update_index();
            if (AUTOCOMMIT) this.save_playlist();
        },
        delete_item: function (item, index, e) {
            e.preventDefault();
            this.items.splice(index, 1);
            this.update_index();
            if (AUTOCOMMIT) this.save_playlist();
        },
        update_index: function () {
            this.items.forEach((item, i) => {
                if (item.position !== i) {
                    item.position = i;
                }
            });
        },
        update_waveforms: function () {
            if (DEBUG) console.debug('PlaylistEditor - update_waveforms');

            for (let item of this.items) {
                if (item.media.waveform) {
                    console.debug('waveform', item.media.waveform);

                    APIClient.get(item.media.waveform)
                        .then((response) => {
                            item.media.waveform_data = response.data.data;
                            this.$forceUpdate();

                        }, (error) => {
                            console.error('PlaylistEditor - error loading waveform', error)
                        })

                }

            }

        },
        ///////////////////////////////////////////////////////////////
        // api handling
        ///////////////////////////////////////////////////////////////
        load_playlist: function () {

            APIClient.get(this.api_url)
                .then((response) => {
                    if (DEBUG) console.debug('PlaylistEditor - loaded items', response.data);
                    this.items = response.data.items;
                    this.update_waveforms();
                }, (error) => {
                    console.error('PlaylistEditor - error loading item', error)
                })
        },
        save_playlist: debounce(function () {

            this.is_processing = true;

            let data = {
                items: this.items,
            };

            APIClient.post(this.api_url, data)
                .then((response) => {
                    if (DEBUG) console.debug('PlaylistEditor - saved items', response.data);
                    this.is_processing = false
                    //this.items = response.data.items;
                }, (error) => {
                    console.error('PlaylistEditor - error loading item', error);
                    this.is_processing = false
                })
        }, 300),
    }
}
