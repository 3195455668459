// legacy stylesheet imports
// import '../sass/screen.sass'

// global stylesheet import
import '../style/main.scss';


// legacy jquery setup
global.$ = require('jquery');
global.jQuery = global.$;

import settings from './settings';

import Turbolinks from 'turbolinks';
import Vue from 'vue';
import Vuex from 'vuex';
import {RatingApp} from './apps/rating.js';
import {RelationEditorApp} from './apps/relation-editor.js';
import {NavigationApp} from './components/navigation.js';
import {HTMLExtraApp} from './components/html-extra.js';

const DEBUG = true;
const USE_TURBOLINKS = !settings.toolbarEnabled;

// site apps
import AppInitializer from './initializer';
import Analytics from './utils/analytics';


// turbolinks event handling
const contentChangedEvent = new Event('content:changed');

document.addEventListener("DOMContentLoaded", (e) => {
    document.dispatchEvent(contentChangedEvent);
});

document.addEventListener('turbolinks:load', (e) => {

    if (Object.keys(e.data.timing).length > 0) {
        if (DEBUG) console.debug('turbolinks loaded sequential request');
        document.dispatchEvent(contentChangedEvent);

    } else {
        if (DEBUG) console.debug('turbolinks loaded initial request');
        document.dispatchEvent(contentChangedEvent);
    }

});


const anayltics = new Analytics();


$(() => {
    // initializer has to wait for dom ready, as
    // vue apps need container to mount

    const initializer = new AppInitializer({});

    if (USE_TURBOLINKS) {
        Turbolinks.start();
    } else {
        console.warn('turbolinks disabled');
        // for compatibility we have to provide `Turbolinks.visit`
        Turbolinks.visit = (url) => {
            console.debug('patched visit', url);
            document.location.href = url;
        }
    }
});


///////////////////////////////////////////////////////////////////////
// TODO: remove / refactor legacy js below
///////////////////////////////////////////////////////////////////////

require('trumbowyg');
require('simplelightbox');
require('jquery-datetimepicker');


global.Vue = require('vue');
global.cookies = require('js-cookie');
global.NotificationApp = require('./apps/notification.js');
global.ChatApp = require('./apps/chat.js');
global.QuickSearchApp = require('./components/quick-search.js');


Vue.use(Vuex);

require('./lib/el-pagination.js');
require('./lib/csrf.js');
require('./components/fb.js');

global.PlayerApp = require('./apps/player.js');

global.sortable = require('html5sortable');
global.MediaEditorApp = require('./apps/media-editor.js');
global.PublisherApp = require('./apps/publisher.js');
// global.HomeApp = require('./components/home.js');


require('./apps/site.js');

global.player = null;
global.search = null;
global.media_editor = null;
global.relation_editor = null;

$(() => {

    let chat = new ChatApp({
        user: document.user
    });

    // global.player = new PlayerApp({});
    global.quick_search = new QuickSearchApp({});
    global.media_editor = new MediaEditorApp({});

    const navigation = new NavigationApp({});
    const html_extra = new HTMLExtraApp({});
    // const home = new HomeApp({});
    // const rating = new RatingApp({
    //     ws_url: settings.WEBSOCKET_URL,
    //     user: document.user
    // });
    const relation_editor = new RelationEditorApp({});
    const publisher = new PublisherApp({});

});
