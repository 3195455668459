;

import throttle from 'lodash/throttle';
import APIClient from "../api/client";

const DEBUG = false;

/**********************************************************************
 * handle endless pagination
 *********************************************************************/
class EndlessPaginator {
    /**
     *
     * @param opts
     */
    constructor(opts) {

        if (DEBUG) console.debug('EndlessPaginator - init');

        this.win = $(window);
        this.doc = $(document);
        this.container_selector = '[data-endless-pagination-actions]';
        this.scroll_margin = 400;
        this.pending_requests = [];

        $(document).on('scroll', throttle((e) => {
            this.handle_scroll(e);
        }, 100));

    };

    /**
     *
     */
    handle_scroll(e) {

        const container = $(this.container_selector);

        if (container.length < 1) {
            return;
        }

        if ((this.doc.height() - this.win.height() - this.win.scrollTop()) > this.scroll_margin) {
            return;
        }

        const opts = container.data();
        if (opts.url === undefined || opts.url === '') return; // check if more pages available
        if (this.has_lock(opts.url)) return; // check fragment is already loading

        // load fragment
        container.addClass('is-loading');
        this.add_lock(opts.url);
        APIClient.get(opts.url + `&querystring_key=${opts.querystringKey}`)
            .then((response) => {
                this.release_lock(opts.url);
                $('.card--loading-dummy').remove();
                container.before(response.data).remove();


                console.debug('EndlessPaginator: ppaginated...')
                const contentChangedEvent = new Event('content:changed');
                document.dispatchEvent(contentChangedEvent);


                //container.remove();
            }, (error) => {
                this.release_lock(opts.url);
                console.error('EndlessPaginator - error loading item', error)
            });
    };

    has_lock(url) {
        return this.pending_requests.includes(url);
    };

    add_lock(url) {
        this.pending_requests.push(url);
    };

    release_lock(url) {
        const index = this.pending_requests.indexOf(url);
        if (index > -1) {
            this.pending_requests.splice(index, 1)
        }
    };
}

module.exports = EndlessPaginator;
