/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
import Vue from 'vue';
import { WebSocketBridge } from 'django-channels';
import APIClient from '../api/caseTranslatingClient';
import store from './index';

const DEBUG = true;
const HISTORY_ENDPOINT = '/api/v2/abcast/history-for-object/';



/**********************************************************************
 * websocket handling / store updates
 *********************************************************************/
const ws_scheme = window.location.protocol === "https:" ? "wss" : "ws";
let ws_host = window.location.host;

// just for webpack devserver scenario - connect directly to backend
if(parseInt(window.location.port) === 3000){
    // ws_host = '10.35.20.100:8080';
    ws_host = 'hsosq-next.local:4000';
    console.info('webpack assumed, setting ws host to:', ws_host)
}

const ws_url = ws_scheme + '://' + ws_host + "/ws/rating/";
const wsb = new WebSocketBridge();
wsb.connect(ws_url, null, {debug: DEBUG});


const generateKey = function(objCt, objUuid) {
    return `${objCt}:${objUuid}`;
};

const state = {
    rating: {},
};

const getters = {
    rating: state => state.rating,
    ratingByKey: (state) => (objCt, objUuid) => {
        const key = generateKey(objCt, objUuid);
        console.log('ratingByKey', key);
        return state.rating[key] || null;
    }
};

const mutations = {
    setRating: (state, {key, payload}) => {
        if (DEBUG) console.debug('mutations - rating', key, payload);
        Vue.set(state.rating, key, payload);

        // emit to wsb
        // wsb.send({ct: el.data('ct'), uuid: el.data('uuid'), value: value});
    },
};

const actions = {
    // loadRating: async (context, {objCt, objUuid}) => {
    //     const key = generateKey(objCt, objUuid);
    //     const url = `${HISTORY_ENDPOINT}${key}/`;
    //     context.commit('setRating', {key: key, payload: []});
    //     if (DEBUG) console.debug('actions - loadRating', url);
    //     APIClient.get(url).then((response) => {
    //         context.commit('setRating', {key: key, payload: response.data});
    //     }).catch(() => {
    //         context.commit('setRating', {key: key, payload: null});
    //     });
    // },
    // setRating: async (context, {objCt, objUuid, payload}) => {
    //     const key = generateKey(objCt, objUuid);
    //     // const url = `${HISTORY_ENDPOINT}${key}/`;
    //     context.commit('setRating', {key: key, payload: payload});
    //
    //     if (DEBUG) console.debug('actions - setRating', {key: key, payload: payload});
    //
    //     // if (DEBUG) console.debug('actions - loadRating', url);
    //     // APIClient.get(url).then((response) => {
    //     //     context.commit('setRating', {key: key, payload: response.data});
    //     // }).catch(() => {
    //     //     context.commit('setRating', {key: key, payload: null});
    //     // });
    // },
    loadRating: async (context, {objCt, objUuid}) => {
        // request rating for object
        console.debug('load rating for', objCt, objUuid);
        wsb.send({
            ct: objCt,
            uuid: objUuid
        })
        // const key = generateKey(objCt, objUuid);
        // const url = `${HISTORY_ENDPOINT}${key}/`;
        // context.commit('setRating', {key: key, payload: []});
        // if (DEBUG) console.debug('actions - loadRating', url);
        // APIClient.get(url).then((response) => {
        //     context.commit('setRating', {key: key, payload: response.data});
        // }).catch(() => {
        //     context.commit('setRating', {key: key, payload: null});
        // });
    },
    setRating: async (context, {objCt, objUuid, payload}) => {
        console.debug('set rating for', objCt, objUuid, payload);
        wsb.send({
            ct: objCt,
            uuid: objUuid,
            value: payload,
        })
    },
};

// django-channels v1.* version
// wsb.listen((data, stream) => {
//     console.log('rating from stream:', data);
//     const key = generateKey(data.ct, data.uuid);
//     store.commit('rating/setRating', {key: key, payload: data.rating});
// });

wsb.addEventListener("message", (event) => {
    console.log('rating from stream:', event.data);
    const key = generateKey(event.data.ct, event.data.uuid);
    store.commit('rating/setRating', {key: key, payload: event.data.rating});
});

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
