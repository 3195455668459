import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import createMutationsSharer from 'vuex-shared-mutations';
import APIClient from '../api/client';

import player from './_player';
import rating from './_rating';

const DEBUG = false;

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        count: 0,
        settings: {
            autoplay: false,
            search_hints_enabled: true
        },
    },
    // https://stackoverflow.com/questions/40390411/vuex-2-0-dispatch-versus-commit
    // mutations: use with 'store.commit'
    mutations: {
        update_settings(state, obj) {
            state.settings[obj.key] = obj.value;
        },
    },
    // actions: use with 'store.dispatch'
    actions: {

    },
    namespaced: true,
    modules: {
        player,
        rating,
    },
    plugins: [
        createPersistedState({
            key: 'store'
        }),
        createMutationsSharer({
            predicate: [
                'update_settings',
            ]
        })
    ],

});
