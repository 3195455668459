<script>

import cookie from 'json-cookie';
import APIClient from '../api/client';
import ClickOutside from 'vue-click-outside';
import Modal from '../components/modal.vue'

const DEBUG = false;

export default {
    name: 'AccountApp',
    components: {
        Modal
    },
    props: [
        'loginUrl',
        'registerUrl',
    ],
    data() {
        return {
            show_modal: false,
            is_authenticated: false,
            login_body: '<h1>foo</h1>',
            register_body: ''
        }
    },
    mounted() {

        $(document).on('click', '[data-login-required]', (e) => {
            const user = this.get_user();
            if(! user) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                this.set_pickup();
                this.show('login');
            }
        });

        /*
        $(document).on('click', '[data-auth-dialog]', (e) => {

            e.preventDefault();

            const scope = $(e.currentTarget).data('auth-dialog');
            const location = $(e.currentTarget).data('auth-dialog-next');

            this.set_pickup(location);
            this.show(scope);

        });
        */

        $(document).on('submit', 'form.account-partial', (e) => {
            e.preventDefault();
            this.process_form(e.currentTarget);
        });
    },
    directives: {
        ClickOutside
    },
    computed: {
        // user: function () {
        //     console.debug('AccountApp - computed:user');
        //     return document.user;
        // }
    },
    methods: {

        get_user: function() {
            return document.user;
        },

        set_pickup: function(location) {

            let auth_pickup = {
                location: location || document.location.pathname
            };

            console.debug(auth_pickup)

            cookie.set('auth-pickup', auth_pickup, {
                expires: 1,
                path: '/'
            });

            console.log('AccountApp - set_pickup', auth_pickup);
        },

        show: function(scope='login') {
            this.show_modal = true;
            this.login_body = '<span></span>';

            let url = null;

            if(scope === 'login') {
                url = this.loginUrl;
            }

            if(scope === 'register') {
                url = this.registerUrl;
            }

            APIClient.get(url)
                .then((response) => {
                    this.login_body = response.data;
                }, (error) => {
                    console.error('AccountApp - error loading item', error)
                });
        },
        hide: function() {
            this.show_modal = false;
        },
        visit_url: function(url, e) {
            this.hide();
            Turbolinks.visit(url);
        },

        process_form(form) {

            const _form = $(form);
            const url = _form.attr('action');

            const form_data = new FormData();

            for (const x of _form.serializeArray()) {
                form_data.set(x.name, x.value);
            }

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencode'
                }
            };

            APIClient.post(url, form_data, config)
                .then((response) => {
                    if(response.data.location !== undefined) {
                        this.hide();
                        Turbolinks.clearCache();

                        if(response.data.location.includes('/pick-up/')) {
                            document.location.href = response.data.location;
                        } else {
                            Turbolinks.visit(document.location.pathname);
                        }

                    } else {
                        this.login_body = response.data;
                    }
                }, (error) => {
                    console.warn(error)
                })
        }


    }
}
</script>
<style lang="scss" scoped>

</style>
<template>
    <modal :show="show_modal" @close="show_modal=false">
        <!--<span slot="title">The REAL Title</span>-->
        <div class="account-app" slot="content">
            <div v-html="login_body" class="body_"></div>
        </div>
    </modal>
</template>
