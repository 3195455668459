<script>

    const DEBUG = false;

    import PlayerDetailCurrent from './PlayerDetailCurrent.vue';
    import PlayerDetailQueue from './PlayerDetailQueue.vue';

    export default {
        name: 'PlayerDetail',
        components: {
            'current': PlayerDetailCurrent,
            'queue': PlayerDetailQueue,
        },
        directives: {},
        props: {
            currentItem: {
                type: Object,
                default: null
            },
            itemsToPlay: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                isFullscreen: false,
            }
        },
        mounted: function () {

        },
        computed: {
            settings() {
                // return this.$store.getters['scheduler/settings'];
            },

        },
        methods: {
            setSchedulerSizes: function () {
                const calendarContainer = this.$refs['calendar'];
                const rect = calendarContainer.$el.getBoundingClientRect();
                const calendarWidth = rect.width;
                this.calendarWidth = calendarWidth;
            },
            controls: function (action, item) {
                console.debug(action, item);
                this.$emit('controls', action, item);
            },
            hide: function () {
                this.$emit('hide');
            },
        },
        watch: {},
    }
</script>
<style lang="scss" scoped>

    @import "abstracts/variables";
    @import "abstracts/mixins";

    .player-detail {
        z-index: 899;
        position: fixed;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: #fff;
        display: flex;

        &__close-toggle {
            position: absolute;
            top: 0;
            right: 0;
        }

        .split-panel {
            // display: flex;
            max-width: 920px;
            width: 100%;
            margin: 60px auto 70px;

            display: grid;
            grid-template-columns: 280px auto;
            grid-template-rows: auto;

            grid-template-areas: "current queue";

            @include bp-below(sm) {
                grid-template-columns: auto;
                grid-template-rows: 280px auto;

                grid-template-areas: "current current" "queue queue";
            }


            &__current {
                grid-area: current;
            }

            &__queue {
                grid-area: queue;
                flex-grow: 1;
                overflow-y: auto;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    border-radius: 0;
                    height: 10px;
                    width: 2px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #6f6f6f;
                    border-radius: 0;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 0;
                }
            }


        }
    }

</style>

<template>
    <div
        class="player-detail"
        :fullscreen="isFullscreen">
        <div
            class="player-detail__close-toggle"
            @click="hide">
            X
        </div>
        <div class="split-panel">
            <div class="split-panel__current">
                <current
                    @hide="hide"
                    :item="currentItem"></current>
            </div>
            <div class="split-panel__queue">
                <queue
                    @controls="controls(...arguments)"
                    @hide="hide"
                    :items-to-play="itemsToPlay"></queue>
            </div>
        </div>
    </div>
</template>
