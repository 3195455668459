import APIClient from "../api/client";
import debounce from 'debounce';
import sortable from 'html5sortable';
import Draggable from 'draggable';
import Dropzone from 'dropzone';

//import Vue from 'vue';
//import Sortable from 'vue-sortable';
// Vue.directive('sortable', {
//   inserted: function (el, binding) {
//     new Sortable(el, binding.value || {})
//   }
// })


const DEBUG = false;

export default {
    name: 'ImageEditor',
    data() {
        return {
            images: [],
            is_processing: false
        }
    },
    computed: {
        // playlists: () => store.state.collector.playlists
    },
    mounted() {
        if (DEBUG) console.debug('ImageEditor - mounted');

        // load initial files
        $('[data-current-images] [data-uuid]').each((i, el) => {
            console.debug('ImageEditor - file', el);
            let item = $(el).data();
            item.ppoi = {
                x: item.ppoix,
                y: item.ppoiy
            };
            this.images.push(item);
        });


        $('.image-editor').parents('form').on('submit', (e) => {

            this.is_processing = true;
            // e.preventDefault();
            // e.stopPropagation();
            // e.stopImmediatePropagation();
            let items = [];

            $('.image-editor .sortable-item').each(function (i, el) {
                items.push(
                    $.extend({position: i}, $(el).data())
                )
            });
            $('#id__image_ids').val(JSON.stringify(items));
        });


        setTimeout(() => {
            this.update_sortable();
            this.setup_upload();
        }, 100);


        setTimeout(() => {
            this.update_ppoi_handle();
        }, 500);


    },

    methods: {
        delete_image: function (image, e) {
            e.preventDefault();
            let _del = null;
            $.each(this.images, function (i, el) {
                console.log('image.uuid', image.uuid, 'el.uuid', el.uuid);
                if (image.uuid === el.uuid) {
                    _del = i;
                }
            });

            if (_del !== null) {
                console.debug('to delete:', _del);

                //this.files.splice(_del, 1);
                this.images.splice(_del, 1);

                //delete self.vm.files[_del];
            }
        },
        update_ppoi_handle: function() {

            // TODO: this is an ugly hack...
            $('.ppoi-handle').not('.ppoi-handle-ready').each((i, el) => {

                const handle = $(el);
                const container = handle.parent()[0];
                const figure = handle.parents('figure');

                // set position
                let x = handle.data('ppoix');
                let y = handle.data('ppoiy');

                // console.log('x', x)
                // console.log('y', y)

                const options = {
                    limit: container,
                    //grid: 10,
                    onDragEnd: (el, x, y) => {
                        const handle = $(el);
                        const uuid = handle.parents('[data-uuid]').data('uuid');
                        const figure = handle.parents('figure');

                        const _x = Math.round((x / (Math.floor(figure.width()) - 16)) * 10) / 10;
                        const _y = Math.round((y / (Math.floor(figure.height()) - 16)) * 10) / 10;

                        for (let image of this.images) {
                            if(image.uuid === uuid) {
                                image.ppoi.x = _x;
                                image.ppoi.y = _y;
                            }
                        }
                    }
                };
                let d = new Draggable(el, options);

                // handle.css('top', (y * 100) + '%');
                // handle.css('left', (x * 100) + '%');
                // handle.css('margin-top', '-8px');
                // handle.css('margin-left', '-8px');

                d.set(figure.width() * x, figure.height() * y)

                handle.addClass('ppoi-handle-ready');

            });
        },
        update_sortable: function () {
            if (DEBUG) console.debug('ImageEditor - update_sortable');

            const opts = {
                items: '.sortable-item',
                forcePlaceholderSize: true,
                placeholder: '<div></div>'
            };

            sortable('.image-editor', opts);

        },
        setup_upload: function (e) {
            if (DEBUG) console.debug('ImageEditor - setup_upload');

            Dropzone.autoDiscover = false;

            const opts = {
                headers: {
                    'X-CSRFToken': $("input[name='csrfmiddlewaretoken']").val()
                },
                url: '/_iu/temp-image-upload/', // image_uploader: 'temporary-image-upload'
                clickable: '.select-files-button',
                acceptedFiles: 'image/*'
            };

            const uploader = new Dropzone("#upload_container", opts);

            uploader.on('success', (file, data) => {
                console.log('DATA', data);
                console.log('FILE', file);

                this.images.push(data);

                // remove file from dropzone
                uploader.removeFile(file);

                setTimeout(() => {
                    this.update_sortable();
                }, 100);

                setTimeout(() => {
                    this.update_ppoi_handle();
                }, 1000);

            })


        }
    }
}
