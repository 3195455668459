const DEBUG = false;

import Vue from 'vue';
// import {wrapRequest} from './requests';
import APIClient from "../api/client";
import store from './index';

const NOW_PLAYING_ENDPOINT = '/api/bcmon/now-playing/';

const state = {
    items_to_play: [],
    current_item: null,
    current_item_key: null,
};
const getters = {
    items_to_play: state => state.items_to_play,
    current_item: state => state.current_item,
    current_item_key: state => state.current_item_key,
};
const mutations = {
    update_items_to_play(state, payload) {
        if (DEBUG) console.log('store mutations: update_items_to_play:', payload);
        state.items_to_play = payload;
    },
    update_current_item_key(state, payload) {
        if (DEBUG) console.log('store mutations: update_current_item_key:', payload);
        state.current_item_key = payload;
    }
};

const actions = {
    load_items_to_play(context, payload) {
        if (DEBUG) console.log('store actions: load_items_to_play:', payload);
        const url = '/api/player/play/';

        return new Promise((resolve, reject) => {

            APIClient.put(url, {items: payload.items})
                .then((response) => {
                    if (DEBUG) console.debug('Player - got items to play', response.data.results);
                    const items_to_play = pre_process_loaded_items(response.data.results);
                    switch (payload.opts.mode) {
                        case 'replace':
                            context.commit('update_items_to_play', items_to_play);
                            resolve(items_to_play);
                            break;
                        case 'append':
                            context.commit('update_items_to_play', context.state.items_to_play.concat(items_to_play));
                            resolve(items_to_play);
                            break;
                    }
                }, (error) => {
                    console.error('error loading data:', error);
                    reject(error)
                });
        });
    },
    set_current_item_key(context, payload) {
        if (DEBUG) console.log('store actions: set_current_item_key:', payload);

        context.state.items_to_play.forEach((item_to_play) => {
            item_to_play.items.forEach((item) => {
                if (item.key !== payload) {
                    item = reset_item(item);
                }
            });
        });
        context.commit('update_current_item_key', payload);
    },
    reset_current_items(context) {
        if (DEBUG) console.log('store actions: reset_current_items');
        context.state.items_to_play.forEach((item_to_play) => {
            item_to_play.items.forEach((item) => {
                item = reset_item(item);
            });
        });
    }
};


// setImmediate(() => {
//     load_now_playing(store);
// });


// TODO: move this to appropriate place...
const pre_process_loaded_items = (results) => {
    results.forEach((item_to_play, i) => {
        item_to_play.items.forEach((item, j) => {
            item = pre_process_item(item);
            item.key = `${item.media.uuid}-${i}-${item_to_play.uuid}-${j}`;
        });
    });
    return results;
};

const pre_process_item = (item) => {

    // set properties needed for display
    item.errors = [];
    item.is_current = false;
    item.is_playing = false;
    item.is_buffering = false;
    item.playhead_position = 0;
    item.playhead_position_ms = 0;
    item.from = 0;
    item.duration = parseFloat(item.media.duration * 1000);
    item.to = parseFloat(item.media.duration * 1000);

    return item;
};

const reset_item = (item) => {
    item.is_current = false;
    item.is_playing = false;
    item.playhead_position = 0;
    item.playhead_position_ms = 0;
    return item;
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
